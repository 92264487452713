import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setRole = createAsyncThunk('role/setRole', async (role) => {
  return role;
});

const initialState = {
  authRole: {},
};

const roleSlice = createSlice({
  name: 'role',
  initialState,
  extraReducers: {
    [setRole.fulfilled]: (action) => action.payload,
  },
});

export const selectRole = ({ role }) => role;

export default roleSlice.reducer;
