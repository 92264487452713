import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const setAppConfig = createAsyncThunk('appConfig/GetAppConfigs', async (res) => {
  return res;
});

const appConfigSlice = createSlice({
  name: 'appConfig',
  initialState: {},
  reducers: {},
  extraReducers: {
    [setAppConfig.fulfilled]: (state, action) => action.payload,
  },
});

export const selectAppConfig = ({ appConfig }) => appConfig;

export default appConfigSlice.reducer;
