import { Component } from 'react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { children } = this.props;
    const { hasError, errorInfo, error } = this.state;

    if (hasError) {
      return (
        <div className="bg-white w-full p-24">
          <h1 className="text-32 font-semibold">Something went wrong.</h1>
          <p className="text-16 whitespace-pre-wrap">
            We are sorry for the inconvenience. You can <a href="/">refresh</a> the page and try again.
          </p>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
