/* eslint-disable react/jsx-props-no-spreading */
import { DatePicker } from '@mui/x-date-pickers';
import { Field } from 'formik';

/* Mui Date Picker component */

const MuiDatePicker = (props) => {
  const { name, errors, touched, required, ...rest } = props;

  return (
    <Field
      component={DatePicker}
      name={name}
      format="dd-MM-yy"
      slotProps={{
        textField: {
          variant: 'outlined',
          error: Boolean(errors[name] && touched[name]),
          helperText: errors[name] && touched[name] ? errors[name] : '',
          fullWidth: true,
          required,
        },
      }}
      {...rest}
    />
  );
};

export default MuiDatePicker;
