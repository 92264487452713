/**
 * Authorization Roles
 */

const authRoles = {
  admin: ['Admin', 'Treasury Officer'],
  staff: ['Admin', 'staff'],
  user: ['user'],
  onlyGuest: [],
};

export default authRoles;
