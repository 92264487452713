import { InputAdornment, TextField } from '@mui/material';
import { ErrorMessage, useField, useFormikContext } from 'formik';
import { NumericFormat } from 'react-number-format';
import JwtService from 'src/app/auth/services/jwtService';

/** Currency component */

const Currency = (props) => {
  const { required, placeholder, name, label, errors, touched, disabled, noInputAdornment, sx, onChange, customCurrencyFormat } = props;
  const [field] = useField(name);
  const getCurrValue = customCurrencyFormat ?? JSON.parse(JwtService.getCurrencySymbol());
  const { setFieldValue } = useFormikContext();

  return (
    <NumericFormat
      customInput={TextField}
      name={field.name}
      onBlur={field.onBlur}
      onChange={onChange ?? field.onChange}
      value={field.value}
      thousandSeparator=","
      thousandsGroupStyle="thousand"
      InputProps={{
        startAdornment: noInputAdornment ? null : <InputAdornment position="start">{getCurrValue ?? ''}</InputAdornment>,
      }}
      decimalScale={2}
      fixedDecimalScale
      label={label}
      onValueChange={(val) => {
        setFieldValue(name, val.value);
      }}
      fullWidth
      sx={sx}
      variant="outlined"
      autoComplete="off"
      disabled={disabled}
      required={required}
      placeholder={placeholder}
      isAllowed={(val) => {
        const { formattedValue, floatValue } = val;
        if (floatValue == null) {
          return formattedValue === '';
        }
        return floatValue <= 9999999999;
      }}
      helperText={<ErrorMessage name={name} />}
      error={Boolean(errors[name] && touched[name])}
    />
  );
};

export default Currency;

Currency.defaultProps = {
  placeholder: '0.00',
};
