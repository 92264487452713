import { styled } from '@mui/material/styles';
// import { useTranslation } from 'react-i18next';
import FusePageSimple from '@fuse/core/FusePageSimple';
import SockJsClient from 'react-stomp';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import AssignedQueuePage from './AssignedQueuePage';

const endPoint = process.env.REACT_APP_API_ENDPOINT;
const wsSignageNotification = process.env.REACT_APP_QUEUE_NOTIFICATION_TEST_WEB_SOCKET_TOPIC;

const Root = styled(FusePageSimple)(() => ({
  '& .FusePageSimple-header': {
    // backgroundColor: theme.palette.background.paper,
    // borderBottomWidth: 1,
    // borderStyle: "solid",
    // borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));
const AssignedQueue = () => {
  // const { t } = useTranslation('AssignedQueue');

  const onMessageQueue = (msg) => {
    console.log(msg);
  };
  return (
    <Root
      content={
        <div className="p-24 w-full">
          <SockJsClient
            url={`${endPoint}${jwtServiceConfig.wsSignageNotification}`}
            topics={[`${wsSignageNotification}`]}
            onConnect={() => console.log('connected to queue')}
            onDisconnect={() => console.log('Disconnected queue!')}
            onMessage={onMessageQueue}
            debug={false}
          />
          <AssignedQueuePage />
        </div>
      }
    />
  );
};
export default AssignedQueue;
