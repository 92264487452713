import { Dialog, Box, Button, DialogActions, DialogContent, DialogTitle, Typography, Alert } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog, selectFuseDialogResponse, selectFuseDialogOptions, selectFuseDialogState } from 'app/store/fuse/dialogSlice';

function FuseDialog() {
  const dispatch = useDispatch();
  const state = useSelector(selectFuseDialogState);
  const options = useSelector(selectFuseDialogOptions);
  const showMessage = useSelector(selectFuseDialogResponse);

  return (
    <Dialog
      open={state}
      onClose={() => dispatch(closeDialog())}
      aria-labelledby="fuse-dialog-title"
      classes={{
        paper: 'rounded-8',
      }}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '400px',
          },
        },
      }}
      {...options}
    >
      <DialogTitle
        sx={{
          padding: '16px 7px',
        }}
      >
        {showMessage?.error === true ? (
          <Alert sx={{ borderRaidus: '4px' }} severity="error">
            Error
          </Alert>
        ) : (
          <Alert sx={{ borderRaidus: '4px' }} severity="success">
            {showMessage.title}
          </Alert>
        )}
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography>{showMessage.message}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className="flex flex-row justify-end mt-24">
          <Box className="pl-8 ">
            <Button className="bg-black text-white hover:text-black" onClick={() => dispatch(closeDialog())} variant="contained" size="small">
              OK
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default FuseDialog;
