/* eslint-disable react/jsx-props-no-spreading */
import { InputAdornment, TextField } from '@mui/material';
import { defaultMobileNumberAdornment } from 'app/shared-components/page-utils/pageUtils';
import { Field, ErrorMessage, useFormikContext } from 'formik';

// Contact number component

const ContactNumber = (props) => {
  const { name, errors, touched, ...rest } = props;
  const { setFieldValue } = useFormikContext();

  const handleInputChange = (e) => {
    // Regex to allow numbers only
    const regex = /^[0-9\b]+$/;
    if (e.target.value === '' || regex.test(e.target.value)) {
      setFieldValue(name, e.target.value);
    }
  };

  return (
    <Field
      as={TextField}
      name={name}
      fullWidth
      autoComplete="off"
      variant="outlined"
      onChange={handleInputChange}
      inputProps={{ maxLength: 15 }}
      InputProps={{
        startAdornment: <InputAdornment position="start">{defaultMobileNumberAdornment}</InputAdornment>,
      }}
      helperText={<ErrorMessage name={name} />}
      error={Boolean(errors[name] && touched[name])}
      {...rest}
    />
  );
};

export default ContactNumber;
