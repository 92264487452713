import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const AbandonedFundApplication = lazy(() => import('./public-payment-management/abandoned-fund-application/AbandonedFundApplication'));
const ApproveAbandonedFunds = lazy(() => import('./public-payment-management/approve-abandoned-funds/ApproveAbandonedFunds'));
const ApprovePublicPaymentRequests = lazy(() => import('./public-payment-management/approve-public-payment-requests/ApprovePublicPaymentRequests'));
const MutilatedNotesExchangeApplication = lazy(() => import('./public-payment-management/mutilated-notes-exchange-application/MutilatedNotesExchangeApplication'));
const ProcessAbandonedFundEFTApplications = lazy(() => import('./public-payment-management/process-abandoned-fund-eft-applications/ProcessAbandonedFundEFTApplications'));
const ReviewAbandonedFunds = lazy(() => import('./public-payment-management/review-abandoned-funds/ReviewAbandonedFunds'));
const ReviewPublicPaymentRequests = lazy(() => import('./public-payment-management/review-public-payment-requests/ReviewPublicPaymentRequests'));
const ViewAbandonedFunds = lazy(() => import('./public-payment-management/view-abandoned-funds/ViewAbandonedFunds'));
const ViewAbandonedFundsEFT = lazy(() => import('./public-payment-management/view-abandoned-funds-eft/ViewAbandonedFundsEFT'));
const ViewMutilatedNotesExchangeRequests = lazy(() => import('./public-payment-management/view-mutilated-notes-exchange-requests/ViewMutilatedNotesExchangeRequests'));
const ReceiptForBOB = lazy(() => import('./ReceiptsForBOB'));
const PublicPaymentApplication = lazy(() => import('./public-payment-management/public-payment-application/PublicPaymentApplication'));
const ProcessFEEftRtnApplication = lazy(() => import('./public-payment-management/process-fe-eft-return-application/ProcessFEEftRtnApplication'));

const PublicPaymentsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'module/public-payment-management',
      children: [
        {
          path: '',
          element: <Navigate to="abandoned-fund-application" />,
        },
        {
          path: 'abandoned-fund-application',
          element: <AbandonedFundApplication />,
        },
        {
          path: 'approve-abandoned-funds',
          element: <ApproveAbandonedFunds />,
        },
        {
          path: 'approve-public-payment-requests',
          element: <ApprovePublicPaymentRequests />,
        },
        {
          path: 'mutilated-notes-exchange-application',
          element: <MutilatedNotesExchangeApplication />,
        },
        {
          path: 'process-abandoned-fund-eft-applications',
          element: <ProcessAbandonedFundEFTApplications />,
        },
        {
          path: 'process-fe-eft-applications',
          element: <ProcessFEEftRtnApplication />,
        },
        {
          path: 'review-abandoned-funds',
          element: <ReviewAbandonedFunds />,
        },
        {
          path: 'review-public-payment-requests',
          element: <ReviewPublicPaymentRequests />,
        },
        {
          path: 'view-abandoned-funds',
          element: <ViewAbandonedFunds />,
        },
        {
          path: 'view-abandoned-funds-eft',
          element: <ViewAbandonedFundsEFT />,
        },
        {
          path: 'view-mutilated-notes-exchange-requests',
          element: <ViewMutilatedNotesExchangeRequests />,
        },
        {
          path: 'public-payment-application',
          element: <PublicPaymentApplication />,
        },
        {
          path: 'txn-receipt-success',
          element: <ReceiptForBOB />,
        },
      ],
    },
  ],
};

export default PublicPaymentsConfig;
