import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const EFTApplication = lazy(() => import('./bank-employee-services/eft-application/EFTApplication'));

const ForeignExchangeApplication = lazy(() => import('./bank-employee-services/foreign-exchange-application/ForeignExchangeApplication'));
const OtherPaymentApplication = lazy(() => import('./bank-employee-services/other-payment-application/OtherPaymentApplication'));

const ViewEFTRequests = lazy(() => import('./bank-employee-services/view-eft-requests/ViewEFTRequests'));

const ViewEFTReturns = lazy(() => import('./bank-employee-services/view-eft-returns/ViewEFTReturns'));
const ViewForeignExchangeRequests = lazy(() => import('./bank-employee-services/view-foreign-exchange-requests/ViewForeignExchangeRequests'));
const ViewForeignExchangeReturns = lazy(() => import('./bank-employee-services/view-foreign-exchange-returns/ViewForeignExchangeReturns'));
const ViewPettyCashRequests = lazy(() => import('./bank-employee-services/view-petty-cash-requests/ViewPettyCashRequests'));
const ViewOtherPaymentRequests = lazy(() => import('./bank-employee-services/view-other-payment-requests/ViewOtherPaymentRequests'));
const ViewForeignExchangeEFTReturns = lazy(() => import('./bank-employee-services/view-foreign-exchange-eft-returns/VIewForeignExchangeEftReturns'));

const ReceiptForBOB = lazy(() => import('./ReceiptsForBOB'));

const BankEmployeeServicesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'module/bank-employee-services',
      children: [
        {
          path: '',
          element: <Navigate to="eft-application" />,
        },
        {
          path: 'eft-application',
          element: <EFTApplication />,
        },
        {
          path: 'foreign-exchange-application',
          element: <ForeignExchangeApplication />,
        },
        {
          path: 'other-payment-application',
          element: <OtherPaymentApplication />,
        },
        {
          path: 'view-eft-requests',
          element: <ViewEFTRequests />,
        },
        {
          path: 'view-eft-returns',
          element: <ViewEFTReturns />,
        },
        {
          path: 'view-foreign-exchange-requests',
          element: <ViewForeignExchangeRequests />,
        },
        {
          path: 'view-foreign-exchange-returns',
          element: <ViewForeignExchangeReturns />,
        },
        {
          path: 'view-foreign-exchange-eft-returns',
          element: <ViewForeignExchangeEFTReturns />,
        },
        {
          path: 'view-petty-cash-requests',
          element: <ViewPettyCashRequests />,
        },
        {
          path: 'view-other-payment-requests',
          element: <ViewOtherPaymentRequests />,
        },
        {
          path: 'txn-receipt-success',
          element: <ReceiptForBOB />,
        },
      ],
    },
  ],
};

export default BankEmployeeServicesConfig;
