import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Collection = lazy(() => import('./collection/Collection'));
const BundleCollection = lazy(() => import('./bundle-collection/BundleCollection'));
const Consolidate = lazy(() => import('./consolidate/Consolidate'));
const Consolidated = lazy(() => import('./consolidated-transcations/ConsolidatedTransactions'));
const Search = lazy(() => import('./search/Search'));
const TransactionPage = lazy(() => import('./collection/TransactionPage'));
const Receipts = lazy(() => import('./receipts/Receipts'));
const ViewReceipt = lazy(() => import('./receipts/ViewReceipt'));
const BundleCash = lazy(() => import('./bundle-cash/BundleCash'));
const FundTransfer = lazy(() => import('../fund-transfer/FundTransfer'));
const FundTransferToBank = lazy(() => import('../fund-transfer-to-bank/FundTransferToBank'));
const AssignConsolidation = lazy(() => import('./assign-consolidation/AssignConsolidation'));
const CashCollectionSuccessPage = lazy(() => import('./collection/CollectionSuccessPage'));
const BundleCollectionSuccessPage = lazy(() => import('./bundle-collection/BundleCollectionSuccessPage'));

const OAGConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'module/revenue-collection',
      children: [
        {
          path: '',
          element: <Navigate to="collection" />,
        },
        {
          path: 'collection',
          element: <Collection />,
        },
        {
          path: 'bundle-collection',
          element: <BundleCollection />,
        },
        {
          path: 'consolidate',
          element: <Consolidate />,
        },
        {
          path: 'assign-consolidation',
          element: <AssignConsolidation />,
        },
        {
          path: 'consolidated-transactions',
          element: <Consolidated />,
        },
        {
          path: 'search',
          element: <Search />,
        },
        {
          path: 'txn-success',
          element: <TransactionPage />,
        },
        {
          path: 'receipts',
          element: <Receipts />,
        },
        {
          path: 'bundle-cash',
          element: <BundleCash />,
        },
        {
          path: 'commercial-bank-transfer',
          element: <FundTransferToBank />,
        },
        {
          path: 'oag-commercial-bank-fund-transfer',
          element: <FundTransfer />,
        },
        {
          path: 'cash-collection-success',
          element: <CashCollectionSuccessPage />,
        },
        {
          path: 'bundle-collection-success',
          element: <BundleCollectionSuccessPage />,
        },
        {
          path: 'view-receipt',
          element: <ViewReceipt />,
        },
      ],
    },
  ],
};

export default OAGConfig;
