/* eslint-disable prettier/prettier */
/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-lonely-if */
import store from 'app/store/index';
import { openDialog } from 'app/store/fuse/dialogSlice';

function invalidResponse() {
  return store?.dispatch(
    openDialog({
      response: {
        error: true,
        title: 'Error',
        message: 'Invalid Response!',
      },
    })
  );
}

export default function handleResponse(response) {
  if (!response) return invalidResponse();

  if (response?.data !== null && response?.data !== '') {
    if (response?.data?.responseCode === 0) {
      return store?.dispatch(
        openDialog({
          response: {
            error: false,
            title: response?.data?.message ?? 'error',
            message: response?.data?.data?.message ?? 'error',
          },
        })
      );
    } else {
      if (response?.data?.errors) {
        Array.isArray(response?.data?.errors)
          ? response?.data?.errors.forEach((error) => {
              if (error?.message) {
                return store?.dispatch(
                  openDialog({
                    response: {
                      error: true,
                      title: response?.data?.message,
                      message: error?.message,
                    },
                  })
                );
              }
            })
          : invalidResponse();
      } else {
        invalidResponse();
      }
    }
  } else {
    invalidResponse();
  }
}
