import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const Country = lazy(() => import('./country/Country'));
const States = lazy(() => import('./states/States'));
const City = lazy(() => import('./city/City'));
const LocationOffice = lazy(() => import('./location/LocationOffice'));
const RevenueCodeConfig = lazy(() => import('./revenue-code-configuration/RevenueCodeConfig'));
const RevenueCodeGrpMgmt = lazy(() => import('./revenue-code-group-mgmt/RevenueCodeGrpMgmt'));
const CommercialBank = lazy(() => import('./commercial-bank/CommercialBank'));
const PaymentCategoryMgmt = lazy(() => import('./payment-category-mgmt/PaymentCategoryMgmt'));
const AgencyManagement = lazy(() => import('./agency-management/AgencyManagement'));
const Cbs = lazy(() => import('./cbs/Cbs'));
const Channel = lazy(() => import('./channel/Channel'));
const TransactionType = lazy(() => import('./transaction-type/TransactionType'));
const UserLimits = lazy(() => import('./user-limits/Userlimits'));
const NotificationSettings = lazy(() => import('./notification-settings/NotificationSettings'));
const Settings = lazy(() => import('./settings/Settings'));

const MastersManagementConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'module/masters-management',
      children: [
        {
          path: '',
          element: <Navigate to="country" />,
        },
        {
          path: 'country',
          element: <Country />,
        },
        {
          path: 'states',
          element: <States />,
        },
        {
          path: 'city',
          element: <City />,
        },
        {
          path: 'location-office',
          element: <LocationOffice />,
        },
        {
          path: 'revenue-code',
          element: <RevenueCodeConfig />,
        },
        {
          path: 'revenue-code-group',
          element: <RevenueCodeGrpMgmt />,
        },
        {
          path: 'commercial-bank',
          element: <CommercialBank />,
        },
        {
          path: 'payment-category',
          element: <PaymentCategoryMgmt />,
        },
        {
          path: 'agency-management',
          element: <AgencyManagement />,
        },
        {
          path: 'cbs-account-list',
          element: <Cbs />,
        },
        {
          path: 'channel-connectivity',
          element: <Channel />,
        },
        {
          path: 'transaction-type',
          element: <TransactionType />,
        },
        {
          path: 'user-limits',
          element: <UserLimits />,
        },
        {
          path: 'settings',
          element: <Settings />,
        },
        {
          path: 'notification-settings',
          element: <NotificationSettings />,
        },
      ],
    },
  ],
};

export default MastersManagementConfig;
