import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import axios from 'axios';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import { handleResponse } from 'app/shared-components/index';
import DistributedQueueDetails from './components/distributed-queue-details/DistributedQueueDetails';
import TerminalDetails from './components/service-queue-number/TerminalDetails';

/* Waiting List
 * The Waiting List page will show the active terminal list as well as the waiting list queue information.
 * Both assigned queue and waiting list queue can be removed by the user.
 */

function BankingHallStatusPage() {
  const [queueDetails, setQueueDetails] = useState(undefined);
  const [terminalAssingedQueueDetails, setTerminalAssingedQueueDetails] = useState(undefined);
  const user = useSelector(selectUser);

  const getGeneratedQueueForLocation = async () => {
    await axios
      .post(jwtServiceConfig.terminalGetQueueDistribution, {
        locationCode: user.locationCode,
      })
      .then((res) => {
        if (res.data.responseCode === 0) {
          setQueueDetails(res.data.data);
          console.log(res.data.data);
        } else {
          handleResponse(res);
        }
      });
  };

  const getAssignedQueueForLocation = async () => {
    await axios
      .post(jwtServiceConfig.terminalGetActiveTerminalsAssignedQueue, {
        locationCode: user.locationCode,
      })
      .then((res) => {
        if (res.data.responseCode === 0) {
          setTerminalAssingedQueueDetails(res.data.data);
          console.log(res.data.data);
        } else {
          handleResponse(res);
        }
      });
  };

  /*   Get all data's on render */
  const getApis = () => {
    Promise.all([getGeneratedQueueForLocation(), getAssignedQueueForLocation()]);
  };

  useEffect(() => {
    getApis();
  }, []);

  return (
    <>
      <Box className="my-8">
        <Box className="flex justify-between">
          <Box>
            <Typography className="text-3xl font-semibold tracking-tight leading-8">Waiting List</Typography>
            <h3>
              <span className="text-md">Queue Management &gt; Waiting List</span>
            </h3>
          </Box>
        </Box>
      </Box>
      <div>
        {/* Waiting list queue details component */}
        {queueDetails && (
          <div>
            <Typography className="text-2xl font-semibold tracking-tight leading-8">Queue</Typography>
            <DistributedQueueDetails queueDetails={queueDetails} user={user} getGeneratedQueueForLocation={getGeneratedQueueForLocation} />
          </div>
        )}
        {/* Terminal details component */}
        {terminalAssingedQueueDetails && (
          <div>
            <Typography className="text-2xl font-semibold tracking-tight leading-8 my-8">Assigned Queue</Typography>
            <TerminalDetails
              terminal={terminalAssingedQueueDetails}
              user={user}
              getAssignedQueueForLocation={getAssignedQueueForLocation}
              getGeneratedQueueForLocation={getGeneratedQueueForLocation}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default BankingHallStatusPage;
