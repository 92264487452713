import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

// Navigation config file for all available routes.

const navigationConfig = [
  {
    id: 'dashboards',
    title: 'Dashboard',
    type: 'group',
    children: [
      {
        id: 'P00018',
        title: 'Government Revenue Collection',
        type: 'item',
        icon: 'material-twotone:dashboard',
        url: 'government-revenue-collection',
      },
      {
        id: 'P00014',
        title: 'Admin Dashboard',
        type: 'item',
        icon: 'material-twotone:dashboard',
        url: 'admin-dashboard',
      },
      {
        id: 'P00006',
        title: 'Agency Revenue Collection',
        type: 'item',
        icon: 'material-twotone:dashboard',
        url: 'revenue-collection-dashboard',
      },
      {
        id: 'P00012',
        title: 'Cash in Hand Dashboard',
        type: 'item',
        icon: 'material-twotone:dashboard',
        url: 'daily-collection-dashboard',
      },

      {
        id: 'P00180',
        title: 'Dashboard for Monitoring Cash Movement',
        type: 'item',
        icon: 'material-twotone:dashboard',
        url: 'tracking-system-for-cash-flow',
      },
      // {
      //   id: "P000141",
      //   title: "Goverment Revenue Collection",
      //   type: "item",
      //   icon: "material-twotone:dashboard",
      //   url: "government-revenue-collection",
      // },
    ],
  },
  {
    id: 'module',
    title: 'Functional Modules',
    type: 'group',
    icons: 'heroicons-outline:document',
    children: [
      {
        id: 'P00021',
        title: 'User Management',
        type: 'collapse',
        icon: 'heroicons-solid:user-group',
        children: [
          {
            id: 'P00137',
            title: 'User',
            type: 'item',
            url: 'module/user-management/users',
          },
          {
            id: 'P00136',
            title: 'User Role',
            type: 'item',
            url: 'module/user-management/roles',
          },
          {
            id: 'P00135',
            title: 'Agency Permission',
            type: 'item',
            url: 'module/user-management/agency-permission',
          },
          {
            id: 'P00134',
            title: 'System Permission',
            type: 'item',
            url: 'module/user-management/system-permission',
          },
          {
            id: 'P00272',
            title: 'Import Users',
            type: 'item',
            url: 'module/user-management/import-users',
          },
        ],
      },
      {
        id: 'P00005',
        title: 'Queue Management',
        type: 'collapse',
        icon: 'material-solid:view_headline',
        children: [
          {
            id: 'P00062',
            title: 'Location Configuration',
            type: 'item',
            url: 'module/queue-management/location',
          },
          {
            id: 'P00063',
            title: 'Service Configuration',
            type: 'item',
            url: 'module/queue-management/services',
          },

          {
            id: 'P00064',
            title: 'Teller & Terminal Configuration',
            type: 'item',
            url: 'module/queue-management/terminal',
          },
          {
            id: 'P00106',
            title: 'Teller Service',
            type: 'item',
            url: 'module/queue-management/assigned-queue',
          },
          {
            id: 'P00169',
            title: 'Waiting List',
            type: 'item',
            url: 'module/queue-management/banking-hall-status',
          },
          {
            id: 'P00108',
            title: 'Teller & Terminal Status',
            type: 'item',
            url: 'module/queue-management/terminal-status',
          },
          {
            id: 'P00107',
            title: 'Queue History Report',
            type: 'item',
            url: 'module/queue-management/queue-history',
          },
        ],
      },
      {
        id: 'P00004',
        title: 'Masters Management',
        type: 'collapse',
        icon: 'feather:airplay',
        children: [
          {
            id: 'P00055',
            title: 'Country Configuration',
            type: 'item',
            url: 'module/masters-management/country',
          },
          {
            id: 'P00051',
            title: 'District Configuration',
            type: 'item',
            url: 'module/masters-management/states',
          },
          {
            id: 'P00052',
            title: 'City Configuration',
            type: 'item',
            url: 'module/masters-management/city',
          },

          {
            id: 'P00053',
            title: 'Commercial Bank Configuration',
            type: 'item',
            url: 'module/masters-management/commercial-bank',
          },
          {
            id: 'P00056',
            title: 'Agency Management',
            type: 'item',
            url: 'module/masters-management/agency-management',
          },
          {
            id: 'P00054',
            title: 'Office Location Configuration',
            type: 'item',
            url: 'module/masters-management/location-office',
          },
          {
            id: 'P00059',
            title: 'Channel & Connectivity Setup',
            type: 'item',
            url: 'module/masters-management/channel-connectivity',
          },
          {
            id: 'P00057',
            title: 'CBS Account Configuration',
            type: 'item',
            url: 'module/masters-management/cbs-account-list',
          },
          {
            id: 'P00172',
            title: 'Transaction Type Management',
            type: 'item',
            url: 'module/masters-management/transaction-type',
          },
          {
            id: 'P00170',
            title: 'Revenue Code Configuration',
            type: 'item',
            url: 'module/masters-management/revenue-code',
          },
          {
            id: 'P00171',
            title: 'Revenue Code Group Management',
            type: 'item',
            url: 'module/masters-management/revenue-code-group',
          },
          {
            id: 'P00060',
            title: 'Payment Category Management',
            type: 'item',
            url: 'module/masters-management/payment-category',
          },
          {
            id: 'P00247',
            title: 'User Limit Management',
            type: 'item',
            url: 'module/masters-management/user-limits',
          },
          {
            id: 'P00274',
            title: 'Settings',
            type: 'item',
            url: 'module/masters-management/settings',
          },
          {
            id: 'P00268',
            title: 'Notification Settings',
            type: 'item',
            url: 'module/masters-management/notification-settings',
          },
        ],
      },
      {
        id: 'P00011',
        title: 'Audit Report',
        type: 'collapse',
        icon: 'material-solid:view_headline',
        children: [
          {
            id: 'P00073',
            title: 'Activity Log Report',
            type: 'item',
            url: 'module/audit-report/activity-report',
          },
          {
            id: 'P00074',
            title: 'Reference Search History Report',
            type: 'item',
            url: 'module/audit-report/search-report',
          },
        ],
      },
      {
        id: 'P00241',
        title: 'File Reconciliation',
        type: 'item',
        icon: 'material-solid:view_headline',
        url: 'module/file-reconciliation',
      },
      {
        id: 'P00003',
        title: 'Government Revenue Collection',
        type: 'collapse',
        icon: 'heroicons-outline:office-building',
        children: [
          {
            id: 'P00025',
            title: 'Collection',
            type: 'item',
            url: 'module/revenue-collection/collection',
          },
          {
            id: 'P00029',
            title: 'Bundle Cash',
            type: 'item',
            url: 'module/revenue-collection/bundle-cash',
          },
          {
            id: 'P00026',
            title: 'Bundle Collection',
            type: 'item',
            url: 'module/revenue-collection/bundle-collection',
          },

          {
            id: 'P00031',
            title: 'Consolidate Cash',
            type: 'item',
            url: 'module/revenue-collection/consolidate',
          },
          {
            id: 'P00181',
            title: 'Assign Consolidation',
            type: 'item',
            url: 'module/revenue-collection/assign-consolidation',
          },
          {
            id: 'P00032',
            title: 'Consolidated Deposit',
            type: 'item',
            url: 'module/revenue-collection/consolidated-transactions',
          },
          {
            id: 'P00033',
            title: 'Transaction Receipts',
            type: 'item',
            url: 'module/revenue-collection/receipts',
          },
          {
            id: 'P00028',
            title: 'Search Transaction',
            type: 'item',
            url: 'module/revenue-collection/search',
          },
          {
            id: 'P00105',
            title: 'Bank Transfer Referencing',
            type: 'item',
            url: 'module/revenue-collection/commercial-bank-transfer',
          },
          {
            id: 'P00030',
            title: 'Agency Transfer Referencing',
            type: 'item',
            url: 'module/revenue-collection/oag-commercial-bank-fund-transfer',
          },
        ],
      },
      {
        id: 'P00007',
        title: 'Public Payment Management',
        type: 'collapse',
        icon: 'material-solid:payment',
        children: [
          {
            id: 'P00065',
            title: 'Abandoned Fund Application',
            type: 'item',
            url: 'module/public-payment-management/abandoned-fund-application',
          },
          {
            id: 'P00071',
            title: 'View Abandoned Funds',
            type: 'item',
            url: 'module/public-payment-management/view-abandoned-funds',
          },
          {
            id: 'P00244',
            title: 'View Abandoned Funds EFT',
            type: 'item',
            url: 'module/public-payment-management/view-abandoned-funds-eft',
          },
          {
            id: 'P00066',
            title: 'Review Abandoned Funds',
            type: 'item',
            url: 'module/public-payment-management/review-abandoned-funds',
          },
          {
            id: 'P00067',
            title: 'Approve Abandoned Funds',
            type: 'item',
            url: 'module/public-payment-management/approve-abandoned-funds',
          },
          {
            id: 'P00243',
            title: 'Process Abandoned Fund EFT Applications',
            type: 'item',
            url: 'module/public-payment-management/process-abandoned-fund-eft-applications',
          },
          {
            id: 'P00279',
            title: 'Process Foreign Exchange EFT Applications',
            type: 'item',
            url: 'module/public-payment-management/process-fe-eft-applications',
          },
          {
            id: 'P00070',
            title: 'Mutilated Notes Exchange Application',
            type: 'item',
            url: 'module/public-payment-management/mutilated-notes-exchange-application',
          },
          {
            id: 'P00252',
            title: 'View Mutilated Notes Exchange Requests',
            type: 'item',
            url: 'module/public-payment-management/view-mutilated-notes-exchange-requests',
          },
          {
            id: 'P00068',
            title: 'Review Public Payment Requests',
            type: 'item',
            url: 'module/public-payment-management/review-public-payment-requests',
          },
          {
            id: 'P00069',
            title: 'Approve Public Payment Requests',
            type: 'item',
            url: 'module/public-payment-management/approve-public-payment-requests',
          },
          {
            id: 'P00265',
            title: 'Public Payment Application',
            type: 'item',
            url: 'module/public-payment-management/public-payment-application',
          },
        ],
      },
      {
        id: 'P00008',
        title: 'Bank Employee Services',
        type: 'collapse',
        icon: 'material-solid:payments',
        children: [
          {
            id: 'P00076',
            title: 'EFT Application',
            type: 'item',
            url: 'module/bank-employee-services/eft-application',
          },
          {
            id: 'P00083',
            title: 'Foreign Exchange Application',
            type: 'item',
            url: 'module/bank-employee-services/foreign-exchange-application',
          },
          {
            id: 'P00013',
            title: 'Other Payment Application',
            type: 'item',
            url: 'module/bank-employee-services/other-payment-application',
          },

          {
            id: 'P00077',
            title: 'View EFT Requests',
            type: 'item',
            url: 'module/bank-employee-services/view-eft-requests',
          },
          {
            id: 'P00078',
            title: 'View EFT Returns',
            type: 'item',
            url: 'module/bank-employee-services/view-eft-returns',
          },
          {
            id: 'P00084',
            title: 'View Foreign Exchange Requests',
            type: 'item',
            url: 'module/bank-employee-services/view-foreign-exchange-requests',
          },
          {
            id: 'P00085',
            title: 'View Foreign Exchange Returns',
            type: 'item',
            url: 'module/bank-employee-services/view-foreign-exchange-returns',
          },
          {
            id: 'P00280',
            title: 'View Foreign Exchange EFT Returns',
            type: 'item',
            url: 'module/bank-employee-services/view-foreign-exchange-eft-returns',
          },
          {
            id: 'P00093',
            title: 'View Petty Cash Requests',
            type: 'item',
            url: 'module/bank-employee-services/view-petty-cash-requests',
          },
          {
            id: 'P00242',
            title: 'View Other Payment Requests',
            type: 'item',
            url: 'module/bank-employee-services/view-other-payment-requests',
          },
        ],
      },
      {
        id: 'P00009',
        title: 'Bank Employee Service Management',
        type: 'collapse',
        icon: 'material-solid:payments',
        children: [
          {
            id: 'P00079',
            title: 'Process EFT Applications',
            type: 'item',
            url: 'module/bank-employee-service-management/process-eft-applications',
          },
          {
            id: 'P00080',
            title: 'Process EFT Returns',
            type: 'item',
            url: 'module/bank-employee-service-management/process-eft-returns',
          },
          {
            id: 'P00081',
            title: 'Review EFT Returns',
            type: 'item',
            url: 'module/bank-employee-service-management/review-eft-returns',
          },
          {
            id: 'P00082',
            title: 'Approve EFT Returns',
            type: 'item',
            url: 'module/bank-employee-service-management/approve-eft-returns',
          },
          {
            id: 'P00086',
            title: 'Review Foreign Exchange Requests',
            type: 'item',
            url: 'module/bank-employee-service-management/review-foreign-exchange-requests',
          },
          {
            id: 'P00087',
            title: 'Approve Foreign Exchange Requests',
            type: 'item',
            url: 'module/bank-employee-service-management/approve-foreign-exchange-requests',
          },
          {
            id: 'P00088',
            title: 'Process Foreign Exchange Applications',
            type: 'item',
            url: 'module/bank-employee-service-management/process-foreign-exchange-applications',
          },
          {
            id: 'P00089',
            title: 'Process Foreign Exchange Returns',
            type: 'item',
            url: 'module/bank-employee-service-management/process-foreign-exchange-returns',
          },
          {
            id: 'P00090',
            title: 'Review Foreign Exchange Returns',
            type: 'item',
            url: 'module/bank-employee-service-management/review-foreign-exchange-returns',
          },
          {
            id: 'P00091',
            title: 'Approve Foreign Exchange Returns',
            type: 'item',
            url: 'module/bank-employee-service-management/approve-foreign-exchange-returns',
          },
          {
            id: 'P00094',
            title: 'Petty Cash Request Application',
            type: 'item',
            url: 'module/bank-employee-service-management/petty-cash-request-application',
          },
          {
            id: 'P00095',
            title: 'View Petty Cash Requests',
            type: 'item',
            url: 'module/bank-employee-service-management/view-petty-cash-requests',
          },
          {
            id: 'P00097',
            title: 'Review Petty Cash Requests',
            type: 'item',
            url: 'module/bank-employee-service-management/review-petty-cash-requests',
          },
          {
            id: 'P00098',
            title: 'Approve Petty Cash Requests',
            type: 'item',
            url: 'module/bank-employee-service-management/approve-petty-cash-requests',
          },

          {
            id: 'P00099',
            title: 'Review Petty Cash Returns',
            type: 'item',
            url: 'module/bank-employee-service-management/review-petty-cash-returns',
          },
          {
            id: 'P00100',
            title: 'Approve Petty Cash Returns',
            type: 'item',
            url: 'module/bank-employee-service-management/approve-petty-cash-returns',
          },
        ],
      },
      {
        id: 'P00245',
        title: 'Teller Assisted Services',
        type: 'collapse',
        icon: 'material-solid:payments',
        children: [
          {
            id: 'P00246',
            title: 'Accept Transaction',
            type: 'item',
            url: 'module/teller-assisted-services/accept-transaction',
          },
          {
            id: 'P00075',
            title: 'Transaction Cancellation',
            type: 'item',
            url: 'module/teller-assisted-services/transaction-cancellation',
          },
        ],
      },

      {
        id: 'P00017',
        title: 'Report',
        type: 'collapse',
        icon: 'heroicons-outline:office-building',
        children: [
          {
            id: 'P00122',
            title: 'Customer Payment Report',
            type: 'item',
            url: 'module/report/customer-payment-report',
          },
          {
            id: 'P00121',
            title: 'Bundle Report',
            type: 'item',
            url: 'module/report/bundle-report',
          },
          {
            id: 'P00119',
            title: 'Bundle Acceptance Report',
            type: 'item',
            url: 'module/report/bundle-acceptance-report',
          },
          {
            id: 'P00120',
            title: 'Consolidation Report',
            type: 'item',
            url: 'module/report/consolidation-report',
          },
          {
            id: 'P00225',
            title: 'Assigned Consolidation Report',
            type: 'item',
            url: 'module/report/assign-consolidation-report',
          },
          {
            id: 'P00126',
            title: 'Daily Payment Report',
            type: 'item',
            url: 'module/report/daily-payment-report',
          },
          {
            id: 'P00125',
            title: 'EFT Report',
            type: 'item',
            url: 'module/report/eft-report',
          },
          {
            id: 'P00127',
            title: 'Foreign Exchange Report',
            type: 'item',
            url: 'module/report/foreign-exchange-report',
          },
          {
            id: 'P00124',
            title: 'Mutilated Notes Report',
            type: 'item',
            url: 'module/report/mutilated-notes-report',
          },
          {
            id: 'P00118',
            title: 'Abandoned Fund Report',
            type: 'item',
            url: 'module/report/abandoned-fund-report',
          },
          {
            id: 'P00231',
            title: 'Partial Deposit Report',
            type: 'item',
            url: 'module/report/partial-deposit-report',
          },
          {
            id: 'P00233',
            title: 'CRM Transaction Report',
            type: 'item',
            url: 'module/report/crm-report',
          },
          {
            id: 'P00235',
            title: 'Coins Transaction Report',
            type: 'item',
            url: 'module/report/coinsreport',
          },
          {
            id: 'P00123',
            title: 'Commercial Bank Deposit Report',
            type: 'item',
            url: 'module/report/com-bank-deposit-report',
          },
          {
            id: 'P00175',
            title: 'Commercial Bank Fund Transfer Report',
            type: 'item',
            url: 'module/report/com-bank-fund-transfer-report',
          },
          {
            id: 'P00267',
            title: 'Completed Petty Cash Report',
            type: 'item',
            url: 'module/report/petty-cash-report',
          },
          {
            id: 'P00276',
            title: 'Other Staff Payments Report',
            type: 'item',
            url: 'module/report/other-staff-payments-report',
          },
        ],
      },
    ],
  },
];

export default navigationConfig;
