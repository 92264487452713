import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import format from 'date-fns/format';

const Terminal = ({ item }) => {
  return (
    <Card className="flex flex-col w-[300px] h-[300px] rounded-lg shadow rounded-lg hover:shadow-xl transition-shadow duration-150 ease-in-out scroll-y-auto">
      <div className="p-16">
        <div className="p-8 flex justify-center items-center bg-gray-200 rounded-lg">
          <Typography className="text-3xl font-bold leading-tight">{item?.terminalName}</Typography>
        </div>
      </div>
      {item?.queueNumber !== null && (
        <div className="pt-12 pl-12">
          <div className="">
            <div className="flex justify-center items-center  ">
              <Typography className="text-xl font-bold leading-tight">{item?.queueNumber === null ? 'NA' : item?.queueNumber}</Typography>
            </div>
            <div className="grid sm:grid-cols-2 sm:grid-rows-auto gap-8 mt-20 p-16">
              {item?.queueNumberGenerationDatetime && (
                <div>
                  <Typography className=" text-left">Issued</Typography>
                  <Typography className="font-sm font-semibold inline-block break-all">
                    {item?.queueNumberGenerationDatetime === null ? 'NA' : format(new Date(item?.queueNumberGenerationDatetime), 'HH:mm:ss')}
                  </Typography>
                </div>
              )}
              {item?.queueNumberAssignmentDatetime && (
                <div>
                  <Typography className=" text-left">Attempted</Typography>
                  <Typography className="font-sm font-semibold inline-block break-all">
                    {item?.queueNumberAssignmentDatetime === null ? 'NA' : format(new Date(item?.queueNumberAssignmentDatetime), 'HH:mm:ss')}
                  </Typography>
                </div>
              )}
              {item?.serviceName && (
                <div>
                  <Typography className=" text-left">Service</Typography>
                  <Typography className="font-sm font-semibold inline-block break-all">{item?.serviceName === null ? 'NA' : item?.serviceName}</Typography>
                </div>
              )}
              {item?.userCode && (
                <div>
                  <Typography className=" text-left">User ID</Typography>
                  <Typography className="font-sm font-semibold inline-block break-all">{item?.userCode === null ? 'NA' : item?.userCode}</Typography>
                </div>
              )}
              {item?.agencyCode && (
                <div>
                  <Typography className=" text-left">Agency</Typography>
                  <Typography className="font-sm font-semibold inline-block break-all">{item?.agencyCode === null ? 'NA' : item?.agencyCode}</Typography>
                </div>
              )}
              {item?.stagingRefNum && (
                <div className="col-span-2">
                  <Typography className=" text-left">Staging Reference Number</Typography>
                  <Typography className="font-sm font-semibold inline-block break-all">{item?.stagingRefNum === null ? 'NA' : item?.stagingRefNum}</Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default Terminal;
