import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import { selectLoader } from 'app/store/loaderSlice';

function SimpleBackdropLoader() {
  const isLoader = useSelector(selectLoader);
  return (
    <Backdrop
      sx={{
        color: '#fff',
        zIndex: (theme) => Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
      }}
      open={isLoader}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default SimpleBackdropLoader;
