import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { selectUser } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import { getSessionRedirectUrl } from '@fuse/core/FuseAuthorization/sessionRedirectUrl';
import JwtService from '../../auth/services/jwtService';

const SignOutPage = () => {
  const user = useSelector(selectUser);

  useEffect(() => {
    /* Removing redirectURL */
    if (getSessionRedirectUrl()) {
      window.sessionStorage.removeItem('DRCSRedirectUrl');
    }
    setTimeout(() => {
      /* Terminate the user token and logging out the user */
      JwtService.deleteToken(user);
    }, 1000);
  }, [user]);

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="flex items-center w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <img className="w-80 mx-auto" src="assets/images/logo/logo2.png" alt="logo" />
          <Typography className="mt-32 text-3xl font-extrabold tracking-tight leading-tight text-center">You have signed out!</Typography>
        </div>
      </Paper>
    </div>
  );
};

export default SignOutPage;
