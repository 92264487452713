/* eslint-disable no-underscore-dangle */
/* eslint-disable prettier/prettier */
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import _ from '@lodash';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
//
import { useDispatch } from 'react-redux';
import { openDialog } from 'app/store/fuse/dialogSlice';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { logoutUser } from 'app/store/userSlice';
import CustomDialogBox from 'app/shared-components/custom-dialog-box/CustomDialog';
import { DialogContent, DialogTitle } from '@mui/material';
import jwtService from '../../auth/services/jwtService';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  userName: yup
    .string()
    .required('You must enter a username')
    .matches(/^[_.0-9a-zA-Z]+$/, 'Please enter letters, numbers, underscore and dots only'),
  password: yup.string().required('Please enter your password.').min(4, 'Password is too short - must be at least 4 chars.'),
});

/**
 * OTP Form Validation Schema
 */
const schemaOtp = yup.object().shape({
  otp: yup.string().required('Required'),
});

const defaultValues = {
  userName: '',
  password: '',
  remember: true,
};

function SignInPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [isOtpModal, setIsOtpModal] = useState(false);
  const [isValues, setIsValues] = useState({
    userName: false,
    password: false,
  });
  const [isFoused, setIsFocused] = useState({
    userName: false,
    password: false,
  });
  const [loginUserName, setLoginUserName] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* useForm Hook for login & password textfields */

  const { control, formState, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  /* useForm Hook for OTP textfield */

  const {
    control: controlOtp,
    formState: formStateOtp,
    handleSubmit: handleSubmitOtp,
    setError: setErrorOtp,
    setValue: setValueOtp,
    clearErrors: clearErrorsOtp,
  } = useForm({
    mode: 'onChange',
    defaultValues: { otp: '' },
    resolver: yupResolver(schemaOtp),
  });

  const { isValid: isValidOtp, dirtyFields: dirtyFieldsOtp, errors: errorsOtp } = formStateOtp;

  useEffect(() => {
    jwtService.currencyFormat();
  }, []);

  function onSubmit({ userName, password }) {
    jwtService
      .signInWithEmailAndPassword(userName.trim(), password.trim())
      .then(() => {
        // console.log(user);
        // No need to do anything, user data will be set at app/auth/AuthContext
      })
      .catch((_errors) => {
        console.log(_errors);
        /* Changing Route to Change Password Module */
        if (_errors?.responseCode === 170) {
          navigate('/change-password', { state: _errors.data });
          /* Open two-factor popup if responsecode 215  */
        } else if (_errors?.responseCode === 215) {
          setValueOtp('otp', '');
          setLoginUserName(userName);
          setIsOtpModal(true);
        } else if (_errors?.responseCode === 0 && _errors?.data?.token === null) {
          dispatch(
            openDialog({
              response: {
                error: true,
                title: 'Authentication Failed',
                message: 'Please contact the Admin',
              },
            })
          );
          dispatch(logoutUser());
        } else {
          dispatch(
            openDialog({
              response: {
                error: true,
                title: _errors?.message,
                message: _errors?.errors[0]?.message,
              },
            })
          );
        }
      });
  }

  /* Prevent Text Copy Paste */
  const handlePreventCopyPaste = (e) => {
    e.preventDefault();
  };

  /* Fix for Browser Autofill Overlapping Text */

  const makeAnimationStartHandler = (val) => (e) => {
    const autofilled = !!e.target?.matches('*:-webkit-autofill');
    if (e.animationName === 'mui-auto-fill') {
      if (val === 'userName') {
        setIsValues((prev) => ({ ...prev, userName: autofilled }));
      } else if (val === 'password') {
        setIsValues((prev) => ({ ...prev, password: autofilled }));
      }
    }
    if (e.animationName === 'mui-auto-fill-cancel') {
      if (val === 'userName') {
        setIsValues((prev) => ({ ...prev, userName: autofilled }));
      } else if (val === 'password') {
        setIsValues((prev) => ({ ...prev, password: autofilled }));
      }
    }
  };

  // Two-Factor Authentication

  function onSubmitOtp({ otp }) {
    jwtService
      .signInWithOtp(loginUserName, otp)
      .then((user) => {
        console.log(user);
      })
      .catch((_errors) => {
        console.log(_errors);
        /* Changing Route to Change Password Module */
        if (_errors?.responseCode === 170) {
          navigate('/change-password', { state: _errors.data });
        } else if (_errors?.responseCode === 0 && _errors?.data?.token === null) {
          dispatch(
            openDialog({
              response: {
                error: true,
                title: 'Authentication Failed',
                message: 'Please contact the Admin',
              },
            })
          );
          dispatch(logoutUser());
        } else if (_errors?.responseCode === 211) {
          _errors.errors.forEach((error) => {
            setErrorOtp('otp', {
              type: 'manual',
              message: error.message,
            });
          });
        } else {
          dispatch(
            openDialog({
              response: {
                error: true,
                title: _errors?.message,
                message: _errors?.errors[0]?.message,
              },
            })
          );
        }
      });
  }

  const maskPassword = {
    WebkitTextSecurity: showPassword ? 'none' : 'disc',
  };
  return (
    <>
      <div className="flex flex-col sm:flex-row items-center md:items-start sm:justify-center md:justify-start flex-1 min-w-0">
        <Paper className="h-full sm:h-auto md:flex md:items-center md:justify-end w-full sm:w-auto md:h-full md:w-1/2 py-8 px-16 sm:p-48 md:p-64 sm:rounded-2xl md:rounded-none sm:shadow md:shadow-none ltr:border-r-1 rtl:border-l-1">
          <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
            <div className="flex items-center justify-center">
              <img className="w-80" src="assets/images/logo/logo2.png" alt="logo" />
            </div>
            <form name="loginForm" noValidate autoComplete="off" className="flex flex-col justify-center w-full mt-32" onSubmit={handleSubmit(onSubmit)}>
              {/* <input type="text" name="fakeusernameremembered" style={{ display: 'none' }} />
              <input type="password" name="fakepasswordremembered" style={{ display: 'none' }} /> */}
              <Controller
                name="userName"
                control={control}
                render={({ field }) => (
                  <TextField
                    // {...field}
                    className="mb-24"
                    label="Username"
                    type="text"
                    // autoFocus
                    value={field.value}
                    // onChange={field.onChange}
                    onChange={(evnt) => {
                      field.onChange(evnt);
                      setValue('userName', evnt.target.value.toLowerCase());
                    }}
                    // onBlur={field.onBlur}
                    error={!!errors.userName}
                    helperText={errors?.userName?.message}
                    variant="outlined"
                    required
                    inputProps={{
                      onAnimationStart: makeAnimationStartHandler('userName'),
                    }}
                    InputLabelProps={{
                      shrink: isValues.userName || isFoused.userName || control?._formValues?.userName !== '',
                    }}
                    onCut={handlePreventCopyPaste}
                    onCopy={handlePreventCopyPaste}
                    onPaste={handlePreventCopyPaste}
                    onFocus={() => setIsFocused((prev) => ({ ...prev, userName: true }))}
                    onBlur={() => setIsFocused((prev) => ({ ...prev, userName: false }))}
                    fullWidth
                    autoComplete="off"
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <TextField
                    // {...field}
                    className="mb-24"
                    label="Password"
                    value={field.value}
                    onChange={field.onChange}
                    // onBlur={field.onBlur}
                    type="text"
                    inputProps={{ style: { WebkitTextSecurity: showPassword ? 'none' : 'disc' } }}
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: isValues.password || isFoused.password || control._formValues.password !== '',
                    }}
                    autoComplete="off"
                    onCut={handlePreventCopyPaste}
                    onCopy={handlePreventCopyPaste}
                    onPaste={handlePreventCopyPaste}
                    InputProps={{
                      onAnimationStart: makeAnimationStartHandler('password'),
                      endAdornment: control?._formValues?.password && (
                        <InputAdornment position="end">
                          <IconButton aria-label="toggle password visibility" onClick={() => setShowPassword((show) => !show)} onMouseDown={(e) => e.preventDefault()} edge="end">
                            {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onFocus={() => setIsFocused((prev) => ({ ...prev, password: true }))}
                    onBlur={() => setIsFocused((prev) => ({ ...prev, password: false }))}
                    required
                    fullWidth
                  />
                )}
              />
              <div className="flex flex-col sm:flex-row items-center justify-end sm:justify-end">
                <Link className="text-md font-medium" to="/forgot-password">
                  Forgot password?
                </Link>
              </div>
              <Button variant="contained" color="secondary" className=" w-full mt-16" aria-label="Sign in" disabled={_.isEmpty(dirtyFields) || !isValid} type="submit" size="large">
                Sign in
              </Button>
              {/* <Button onClick={() => setIsOtpModal((prev) => !prev)}>open</Button> */}
            </form>
          </div>
        </Paper>

        <Box
          className="relative hidden md:flex flex-auto items-center justify-center h-full p-64 lg:px-112 overflow-hidden"
          sx={{
            backgroundImage: `url(assets/images/SigninPageImage.png)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {/* <svg
          className="absolute inset-0 pointer-events-none"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: "primary.light" }}
            className="opacity-20"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="234" cx="196" cy="23" />
            <circle r="234" cx="790" cy="491" />
          </Box>
        </svg>
        <Box
          component="svg"
          className="absolute -top-64 -right-64 opacity-20"
          sx={{ color: "primary.light" }}
          viewBox="0 0 220 192"
          width="220px"
          height="192px"
          fill="none"
        >
          <defs>
            <pattern
              id="837c3e70-6c3a-44e6-8854-cc48c737b659"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect x="0" y="0" width="4" height="4" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
        </Box> */}

          <div className="z-10 relative w-full max-w-2xl text-center">
            <div className="text-7xl font-bold leading-none text-gray-100">
              <div>Welcome to DRCS</div>
            </div>
            <div className="mt-24 text-lg tracking-tight leading-6 text-gray-400">Digital Revenue Collection System</div>
            {/* <div className="flex items-center mt-32">
            <AvatarGroup
              sx={{
                '& .MuiAvatar-root': {
                  borderColor: 'primary.main',
                },
              }}
            >
              <Avatar src="assets/images/avatars/female-18.jpg" />
              <Avatar src="assets/images/avatars/female-11.jpg" />
              <Avatar src="assets/images/avatars/male-09.jpg" />
              <Avatar src="assets/images/avatars/male-16.jpg" />
            </AvatarGroup>

            <div className="ml-16 font-medium tracking-tight text-gray-400">
              More than 17k people joined us, it's your turn
            </div>
          </div> */}
          </div>
        </Box>
      </div>
      <CustomDialogBox
        open={isOtpModal}
        children={
          <>
            <DialogTitle className="text-right">
              <CancelIcon
                className="text-black hover:cursor-pointer"
                onClick={() => {
                  setIsOtpModal((prev) => !prev);
                  setValueOtp('otp', '');
                  clearErrorsOtp();
                }}
              />
            </DialogTitle>
            <DialogContent>
              <div className="flex flex-col items-center min-w-0">
                <Typography className="text-16 sm:text-20 truncate font-semibold">OTP Verification</Typography>
                <Typography variant="caption" className="font-medium">
                  Enter the one time verification code recieved on your Email ID / Mobile
                </Typography>
              </div>
              <form name="loginForm" noValidate onSubmit={handleSubmitOtp(onSubmitOtp)}>
                <div className="mt-8 p-16">
                  <div>
                    <Controller
                      name="otp"
                      control={controlOtp}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="mb-16"
                          label="Verification Code"
                          type="text"
                          error={!!errorsOtp.otp}
                          helperText={errorsOtp?.otp?.message}
                          variant="outlined"
                          required
                          fullWidth
                          autoComplete="off"
                        />
                      )}
                    />
                  </div>
                  {/* <div className="flex flex-row items-center justify-end my-4 pr-4">
                  <Typography variant="caption" className="font-medium">
                    Resend OTP
                  </Typography>
                </div> */}
                  <div>
                    <Button variant="contained" color="secondary" className=" w-full mt-8" aria-label="Otp Verification" disabled={_.isEmpty(dirtyFieldsOtp) || !isValidOtp} type="submit" size="large">
                      Verify
                    </Button>
                  </div>
                </div>
              </form>
            </DialogContent>
          </>
        }
      />
    </>
  );
}

export default SignInPage;
