import { DialogActions, DialogContent, DialogTitle, Menu, MenuItem, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { memo, useState } from 'react';
import MuiButton from 'app/shared-components/mui/button/MuiButton';
import MuiDialog from 'app/shared-components/mui/dialog/MuiDialog';
import axios from 'axios';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { handleResponse } from 'app/shared-components/index';
import Terminal from './Terminal';

const container = {
  show: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};

const TerminalDetails = ({ terminal, user, getAssignedQueueForLocation, getGeneratedQueueForLocation }) => {
  const [contextMenu, setContextMenu] = useState(null);
  const [pageControl, setPageControl] = useState({ isOpen: false, selectedQueue: '' });
  const { locationCode } = user;

  const handleCloseQueueDltDialog = () => {
    setPageControl((prev) => ({
      ...prev,
      isOpen: false,
      selectedQueue: '',
    }));
  };

  const removeQueue = () => {
    axios
      .post(jwtServiceConfig.queueRemoveQueue, {
        queueNumber: pageControl.selectedQueue,
        locationCode,
      })
      .then((res) => {
        if (res.data.responseCode === 0) {
          setPageControl((prev) => ({ ...prev, isOpen: false, selectedQueue: '' }));
          getAssignedQueueForLocation();
          getGeneratedQueueForLocation();
          handleResponse(res);
        } else {
          handleResponse(res);
        }
      });
  };

  if (!terminal) {
    return null;
  }

  return (
    <div className="mx-4 my-8">
      {terminal?.length === 0 && (
        <div className="flex justify-center items-center">
          <Typography color="text.secondary" className="text-24 my-24">
            No Terminals Found...!
          </Typography>
        </div>
      )}
      <motion.div variants={container} initial="hidden" animate="show" className="grid grid-flow-col auto-cols-max gap-20 mt-20 md:mt-8 overflow-auto p-4">
        {terminal?.map((tml) => (
          <motion.div
            key={tml?.terminalId}
            variants={item}
            className="w-full min-w-full sm:min-w-100 min-h-200"
            style={{ cursor: 'context-menu' }}
            onContextMenu={(e) => {
              e.preventDefault();
              /*   Showing remove option only for terminal with assigned queue */
              if (tml?.queueNumber) {
                setContextMenu(
                  contextMenu === null
                    ? {
                        mouseX: e.clientX + 2,
                        mouseY: e.clientY - 6,
                      }
                    : null
                );
                setPageControl((prev) => ({ ...prev, selectedQueue: tml?.queueNumber }));
              }
            }}
          >
            <Terminal item={tml} />
          </motion.div>
        ))}
      </motion.div>
      <Menu
        open={contextMenu !== null}
        onClose={() => setContextMenu(null)}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem
          onClick={() => {
            setContextMenu(null);
            setPageControl((prev) => ({
              ...prev,
              isOpen: true,
            }));
          }}
        >
          Remove Queue Number
        </MenuItem>
      </Menu>
      <MuiDialog open={pageControl.isOpen}>
        <DialogTitle>
          <Typography className="text-xl font-semibold tracking-tight leading-8">Remove Queue</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography className="text-16 font-semibold tracking-tight">Are you sure want to remove this Queue Number?</Typography>
        </DialogContent>
        <DialogActions>
          <div className="flex">
            <MuiButton type="cancel-btn" className="mx-8" onClick={handleCloseQueueDltDialog}>
              Cancel
            </MuiButton>
            <MuiButton type="basic" onClick={() => removeQueue()}>
              Confirm
            </MuiButton>
          </div>
        </DialogActions>
      </MuiDialog>
    </div>
  );
};

export default memo(TerminalDetails);
