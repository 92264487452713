import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import FusePageSimple from '@fuse/core/FusePageSimple';
import { Box, Paper, Typography, Alert, AlertTitle } from '@mui/material';
import { JSEncrypt } from 'js-encrypt';
import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { handleDateTime, displayCurrencyFormat, publicKey } from 'app/shared-components/page-utils/pageUtils';
import MuiButton from 'app/shared-components/mui/button/MuiButton';

const Root = styled(FusePageSimple)(({ theme }) => ({
  '& .FusePageSimple-header': {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.divider,
  },
  '& .FusePageSimple-toolbar': {},
  '& .FusePageSimple-content': {},
  '& .FusePageSimple-sidebarHeader': {},
  '& .FusePageSimple-sidebarContent': {},
}));

function TransactionPage() {
  const [getData, setGetData] = useState(undefined);
  const [qrData, setQrData] = useState('');
  const { state } = useLocation();
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);

  console.log(state);
  useEffect(() => {
    if (!state) {
      navigate('/');
    } else {
      setGetData(state.data);

      console.log(state.data);
      const encrypted = encrypt.encrypt(JSON.stringify(state.data));
      // console.log(encrypted);
      setQrData(encrypted);
    }
  }, []);

  const printReceipt = (value) => {
    html2canvas(canvasRef.current).then((canvas) => {
      const data = canvas.toDataURL('image/png');
      const pdf = new JsPDF();
      pdf.addImage(data, 'JPEG', 15, 40);
      if (value === 'dwld') {
        pdf.save(`Receipt-${getData.urn ?? getData.stagingRefNum}.pdf`);
      } else if (value === 'prnt') {
        pdf.output('dataurlnewwindow');
      }
    });
  };

  return (
    <Root
      content={
        <div className="p-24 w-full">
          <Paper className="relative flex flex-col flex-auto p-24 prounded-2xl shadow overflow-hidden">
            <Box className="mt-4 ml-8">
              <Box className="flex justify-between">
                <Box>
                  <Typography className="text-3xl font-semibold tracking-tight leading-8">Transaction Details</Typography>
                </Box>
              </Box>
            </Box>
            <Box className="flex justify-between flex-wrap my-20 mx-8 ">
              <Box className="w-[300px]">
                <Box>
                  <Alert severity="success">
                    <AlertTitle>Success</AlertTitle>
                    <Typography className="text-md font-semibold">Your Receipt Generated</Typography>
                  </Alert>
                </Box>
                <Box className="py-20 pl-12 flex flex-row">
                  <MuiButton type="basic" onClick={() => printReceipt('prnt')}>
                    Print
                  </MuiButton>
                  <Box className="px-8">
                    <MuiButton type="basic" onClick={() => printReceipt('dwld')}>
                      Download
                    </MuiButton>
                  </Box>
                </Box>
              </Box>
              <Box className="md:pt-0 p-80 sm:p-0">
                <Paper className="bg-slate-300 shadow overflow-hidden p-20">
                  <Box ref={canvasRef}>
                    <Box className="grid sm:grid-cols-2 sm:grid-rows-auto md:grid-cols-2 md:grid-rows-auto gap-16">
                      <Box className="sm:col-span-2 md:col-span-2">
                        <div className="flex justify-center mb-20">
                          <img width="100" src="assets/images/logo/logo2.png" alt="logo" />
                        </div>
                      </Box>
                      <Box>
                        <Typography>{getData?.urn !== null ? 'URN' : 'Staging Reference Number'}</Typography>
                        <Typography className="col-span-2 font-sm font-semibold inline-block">{getData?.urn !== null ? getData?.urn : getData?.stagingRefNum}</Typography>
                      </Box>
                      <Box className="row-start-2">
                        <Typography>Payer Name</Typography>
                        <Typography className="font-sm font-semibold inline-block">{getData?.payerName}</Typography>
                      </Box>
                      <Box className="row-start-2">
                        <Typography>Amount</Typography>
                        <Typography className="font-sm font-semibold inline-block">{displayCurrencyFormat(Number(getData?.txnAmount))}</Typography>
                      </Box>
                      <Box className="row-start-3">
                        <Typography>Date</Typography>
                        <Typography className="font-sm font-semibold inline-block">{handleDateTime(getData?.datetime, 'D')}</Typography>
                      </Box>
                      <Box className="row-start-3">
                        <Typography>Location</Typography>
                        <Typography className="font-sm font-semibold inline-block">{getData?.locationCode}</Typography>
                      </Box>
                      {/* <Box>
                      <Typography>Collection Officer ID</Typography>
                      <Typography className="font-sm font-semibold inline-block">7845</Typography>
                    </Box> */}

                      <Box className="row-start-4">
                        <Typography>Payment Mode</Typography>
                        <Typography className="font-sm font-semibold inline-block">{getData?.pymntMode === 'CSH' ? 'Cash' : 'Card'}</Typography>
                      </Box>
                    </Box>
                    <Box className="my-28 flex justify-center">
                      {qrData !== '' ? (
                        <QRCode
                          size={256}
                          style={{
                            width: '180px',
                            height: '180px',
                          }}
                          value={qrData}
                          viewBox="0 0 256 256"
                        />
                      ) : (
                        ''
                      )}
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Paper>
        </div>
      }
    />
  );
}

export default TransactionPage;
