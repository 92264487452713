import { useRef } from 'react';
import { Box, FormLabel, Card, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import ActionRendererComponent from 'app/shared-components/action-render/ActionRendererComponent';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { handleResponse } from 'app/shared-components/index';
import AgGrid from 'app/shared-components/ag-grid/AgGrid';
import { handleDateTime } from 'app/shared-components/page-utils/pageUtils';
import MuiButton from 'app/shared-components/mui/button/MuiButton';

const OAGComBankTransfer = ({ rowData, setRowData }) => {
  const gridRef = useRef();
  console.log(gridRef);

  const downloadCSVFile = (img, reqId) => {
    console.log(img);
    console.log(reqId);
    const linkSource = `data:application/csv;base64,${img}`;
    const downloadLink = document.createElement('a');
    const fileName = `${reqId}`;
    downloadLink.href = linkSource;
    downloadLink.target = '_blank';
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const viewReceipt = async (value) => {
    const data = {
      fileNameWithPath: `${value.outputFilePath}${value.outputFileName}`,
    };
    console.log(data);
    const res = await axios.post(jwtServiceConfig.fpViewFileBase64, data);
    console.log(res);
    if (res.data.responseCode === 0) {
      const img = res?.data?.data?.base64String;
      const reqId = value?.outputFileName;
      downloadCSVFile(img, reqId);
    } else {
      handleResponse(res);
    }
  };
  const handleView = (data) => {
    viewReceipt(data);
  };

  const columnDefsT24BISSFileOAG = [
    { field: 'id', headerName: 'Job ID' },
    { field: 'fileName', headerName: 'T24/BISS File' },
    { field: 'createdBy', headerName: 'Uploaded By' },
    {
      field: 'createdDateTime',
      headerName: 'DateTime',
      valueGetter: (params) => handleDateTime(params?.data?.createdDateTime),
    },
    { field: 'jobStatus', headerName: 'Status' },
    { field: 'outputFileName', headerName: 'Output File Name' },
    {
      field: 'report',
      headerName: 'Download',
      cellRenderer: ({ data }) => ActionRendererComponent('isViewFile', data, handleView),
    },
  ];
  const getAllUploadedFileDetails = async () => {
    const res = await axios.post(jwtServiceConfig.fpGetAllUploadedFileDetails, {
      jobType: 'OAG_COM',
    });
    console.log(res.data);
    if (res.data.responseCode === 0) {
      setRowData(res.data.data);
    } else {
      handleResponse(res);
    }
  };
  const handleSubmitT24BISSFileOAG = async (values, resetForm) => {
    const formData = new FormData();
    formData.append(
      'jsonData',
      JSON.stringify({
        jobType: 'OAG_COM',
        fileDefinitions: [{ fileName: values?.T24BISSFile.name, definitionName: 'T24' }],
      })
    );
    formData.append('fileList', values?.T24BISSFile);
    const res = await axios.post(jwtServiceConfig.fpUploadMultipleFile, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    console.log(res?.data);
    if (res.data.responseCode === 0) {
      handleResponse(res);
      getAllUploadedFileDetails();
      resetForm();
    } else {
      handleResponse(res);
    }
  };

  return (
    <>
      <Box className=" text-sm">OAG Consolidation Transfers from Commercial Banks File Reconciliation</Box>
      <Box className="mt-20 text-sm font-medium">Select and Upload the files to reconcile</Box>
      <Box>
        <Formik initialValues={{ T24BISSFile: undefined }} onSubmit={handleSubmitT24BISSFileOAG} validateOnBlur={false}>
          {({ values, resetForm, setFieldValue }) => (
            <Box className="my-4 mr-8">
              <Box className="mt-8 ">
                <Form noValidate>
                  <Box className="flex justify-between my-8 pr-16">
                    <Card className="flex flex-col items-start w-full h-[120px] p-24 rounded-none overflow-auto ">
                      <Box className="flex-column my-8">
                        <Box className="my-8">
                          <FormLabel>T24/BISS: (File 1) </FormLabel>
                        </Box>
                        <div className="flex">
                          <div className="flex items-center">
                            <div>
                              <span className=" text-sm ">
                                {values?.T24BISSFile ? values?.T24BISSFile?.name : 'Select File'}
                                {values?.T24BISSFile && (
                                  <CloseIcon
                                    onClick={() => {
                                      setFieldValue('T24BISSFile', null);
                                    }}
                                    style={{ marginLeft: '8px', fontSize: '16px' }}
                                  />
                                )}
                              </span>
                            </div>
                            <div className="mx-8">
                              <MuiButton type="basic" component="label">
                                <Typography className="text-13 font-medium tracking-tight">Browse</Typography>
                                <input
                                  type="file"
                                  name="T24BISSFile"
                                  required
                                  hidden
                                  accept=".csv"
                                  onChange={(event) => {
                                    setFieldValue('T24BISSFile', event.currentTarget.files[0]);
                                  }}
                                />
                              </MuiButton>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </Card>

                    <Box>
                      <Box className="flex justify-end my-20 px-8">
                        <Box>
                          <MuiButton type="basic" className="ml-8" disabled={values?.T24BISSFile === undefined} onClick={() => handleSubmitT24BISSFileOAG(values, resetForm)}>
                            Reconcile
                          </MuiButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Form>
              </Box>
            </Box>
          )}
        </Formik>
      </Box>
      <Box className="mt-32">
        <AgGrid rowData={rowData} columnDefs={columnDefsT24BISSFileOAG} />
      </Box>
    </>
  );
};
export default OAGComBankTransfer;
