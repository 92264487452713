import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const UserPage = lazy(() => import('./users/User'));
const UserRole = lazy(() => import('./user-role/UserRole'));
const SystemPermission = lazy(() => import('./system-permission/SystemPermission'));
const AgencyPermission = lazy(() => import('./agency-permission/AgencyPermission'));
const ImportUsers = lazy(() => import('./import-users/ImportUsers'));

const UserRoleConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'module/user-management',
      children: [
        {
          path: '',
          element: <Navigate to="users" />,
        },
        {
          path: 'users',
          element: <UserPage />,
        },
        {
          path: 'roles',
          element: <UserRole />,
        },
        {
          path: 'system-permission',
          element: <SystemPermission />,
        },
        {
          path: 'agency-permission',
          element: <AgencyPermission />,
        },
        {
          path: 'import-users',
          element: <ImportUsers />,
        },
      ],
    },
  ],
};

export default UserRoleConfig;
