import { useState } from 'react';
import SockJsClient from 'react-stomp';
import { useSelector } from 'react-redux';
import { selectUser } from 'app/store/userSlice';
import { Snackbar, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';

const endPoint = process.env.REACT_APP_API_ENDPOINT;
const urmNotification = process.env.REACT_APP_URM_NOTIFICATION_WEB_SOCKET_TOPIC;
const bobNotification = process.env.REACT_APP_BOB_NOTIFICATION_WEB_SOCKET_TOPIC;

function ConnectWebSocket() {
  const user = useSelector(selectUser);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('You server message here.');

  const onMessageReceivedpwd = (msg) => {
    setOpen(true);
    setMessage(`${msg.userName} - ${msg.message}`);
  };

  const onMessageINAPP = (msg) => {
    setOpen(true);
    console.log('user/queue/bobUserInAppNotification -', msg);
    setMessage(msg);
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function onConnect(value) {
    console.log(value);
  }

  return (
    <Box>
      <div>
        {/* <SockJsClient
            url={urmNotification}
            headers={{ "X-Authorization": `Bearer ${user.token}` }}
            topics={["/user/queue/singleUserUrmNotification"]}
            onConnect={onConnect("connected to websocket single user urm notify")}
            onDisconnect={() => console.log("Disconnected!")}
            onMessage={onMessageReceived}
            debug={false}
          /> */}
        <SockJsClient
          url={`${endPoint}${jwtServiceConfig.urmNotification}`}
          headers={{ 'X-Authorization': `Bearer ${user.token}` }}
          topics={[`${urmNotification}${user?.loginUsername?.toLowerCase()}`]}
          onConnect={onConnect('connected to websocket pwd expiry')}
          onDisconnect={() => console.log('Disconnected pwd expiry!')}
          onMessage={onMessageReceivedpwd}
          debug={false}
        />
        <SockJsClient
          url={`${endPoint}${jwtServiceConfig.bobNotification}`}
          headers={{ 'X-Authorization': `Bearer ${user.token}` }}
          topics={[`${bobNotification}`]}
          onConnect={onConnect('connected to IN-APP')}
          onDisconnect={() => console.log('Disconnected IN-APP!')}
          onMessage={onMessageINAPP}
          debug={false}
        />

        <Box style={{ position: 'absolute', left: '100px', top: '600px' }} className="snackbar">
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            key={message}
            open={open}
            autoHideDuration={6000}
            message={message}
            action={
              <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            }
          />
        </Box>
      </div>
    </Box>
  );
}
export default ConnectWebSocket;
