import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Form } from 'formik';
import MuiButton from '../button/MuiButton';
// import { PropTypes } from 'prop-types';

const MuiFormDialog = ({ open, onClose, dialogTitle, width, children, actionBtnLabel = 'Save', actionBtnCloseLabel = 'Cancel', isLeftDialogActionBtn, isActionBtnFunc, isNoDirty }) => {
  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: width,
            minWidth: width,
          },
        },
      }}
      width={width}
    >
      <DialogTitle>
        {dialogTitle && typeof dialogTitle === 'string' ? (
          <Box className="flex justify-between">
            <Typography className="text-xl font-semibold tracking-tight leading-8">{dialogTitle}</Typography>
          </Box>
        ) : (
          dialogTitle
        )}
      </DialogTitle>
      <Form noValidate>
        <DialogContent
          className="py-8"
          sx={{
            maxHeight: '400px',
            overflow: 'auto',
          }}
        >
          {children}
        </DialogContent>
        <DialogActions className="flex justify-between">
          <Box>
            {isLeftDialogActionBtn && (
              <Button className={isLeftDialogActionBtn.className ?? 'bg-black text-white hover:text-black'} variant="outlined" size="small" onClick={isLeftDialogActionBtn?.handleLeftActionButton}>
                {isLeftDialogActionBtn?.label}
              </Button>
            )}
          </Box>
          <Box>
            <MuiButton className="mr-4" type="cancel-btn" onClick={() => onClose()}>
              {actionBtnCloseLabel}
            </MuiButton>
            {isActionBtnFunc ? (
              <MuiButton type="basic" className="bg-black text-white hover:text-black" onClick={() => isActionBtnFunc()}>
                {actionBtnLabel}
              </MuiButton>
            ) : (
              <MuiButton type="submit" isNoDirty={isNoDirty}>
                {actionBtnLabel}
              </MuiButton>
            )}
          </Box>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

export default MuiFormDialog;

MuiFormDialog.defaultProps = {
  width: '500px',
  // actionBtnLabel: '',
  // actionBtnCloseLabel: '',
  // isLeftDialogActionBtn: {},
};

// MuiFormDialog.propTypes = {
//   open: PropTypes.bool.isRequired,
//   onClose: PropTypes.func.isRequired,
//   dialogTitle: PropTypes.string.isRequired,
//   width: PropTypes.string,
//   children: PropTypes.node.isRequired,
//   actionBtnLabel: PropTypes.string,
//   actionBtnCloseLabel: PropTypes.string,
//   isLeftDialogActionBtn: PropTypes.shape({
//     id: PropTypes.number.isRequired,
//     title: PropTypes.string,
//     className: PropTypes.string,
//     handleLeftActionButton: PropTypes.func,
//     label: PropTypes.string,
//   }),
// };
