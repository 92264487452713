import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const ReceiptForBOB = lazy(() => import('../public-and-staff-payments/ReceiptsForBOB'));
const AcceptTransaction = lazy(() => import('./accept-transaction/AcceptTransaction'));
const TransactionCancellation = lazy(() => import('./transaction-cancellation/TransactionCancellation'));

const TellerAssistedServicesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'module/teller-assisted-services',
      children: [
        {
          path: '',
          element: <Navigate to="accept-transaction" />,
        },
        {
          path: 'accept-transaction',
          element: <AcceptTransaction />,
        },
        {
          path: 'transaction-cancellation',
          element: <TransactionCancellation />,
        },
        {
          path: 'txn-receipt-success',
          element: <ReceiptForBOB />,
        },
      ],
    },
  ],
};

export default TellerAssistedServicesConfig;
