import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Paper, Box, Typography, MenuItem } from '@mui/material';
import AgGrid from 'app/shared-components/ag-grid/AgGrid';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import axios from 'axios';
import { handleDateTime } from 'app/shared-components/page-utils/pageUtils';
import GridContainer from 'app/shared-components/mui/grid/GridContainer';
import { handleResponse } from 'app/shared-components/index';
import MuiButton from 'app/shared-components/mui/button/MuiButton';
import GridItem from 'app/shared-components/mui/grid/GridItem';
import FormikController from 'app/shared-components/formik-controller/FormikController';
import { initialValuesQueueHistoryPage, validationSchemaQueueHistoryPage } from '../helperFuntionQueueManagement';

/* Queue History Report
 * Will display all the queue details for today's queues based on the selected agencycode and location.
 */

const QueueHistoryPage = () => {
  const [showGrid, setShowGrid] = useState(false);
  const [queueLocations, setQueueLocations] = useState([]);
  const [agencyCodes, setAgencyCodes] = useState([]);
  const [rowData, setRowData] = useState(undefined);

  const showQueueStatus = (params) => {
    switch (params) {
      case 0:
        return 'Created';
      case 1:
        return 'Assigned';
      case 2:
        return 'Served';
      case 3:
        return 'Processing';
      case 4:
        return 'Cancelled';
      case 5:
        return 'Invalidation';
      case 6:
        return 'Rescheduled';
      default:
        return 'NA';
    }
  };

  const columnDefs = [
    {
      field: 'queueNumber',
      headerName: 'Queue Number',
    },
    {
      field: 'serviceName',
      headerName: 'Requested Service',
    },
    {
      field: 'terminalName',
      headerName: 'Assigned Teller/Terminal',
    },
    {
      field: 'stagingRefNum',
      headerName: 'Staging Reference Number',
    },
    {
      field: 'userCode',
      headerName: 'User Code',
    },
    {
      field: 'qnumServeStatus',
      headerName: 'Status',
      valueGetter: (params) => showQueueStatus(params?.data?.qnumServeStatus),
    },

    {
      field: 'queueNumberGenerationDatetime',
      headerName: 'Queue Number Generation DateTime',
      valueGetter: ({ data: { queueNumberGenerationDatetime } }) => handleDateTime(queueNumberGenerationDatetime),
    },
    {
      field: 'requestDatetime',
      headerName: 'Request DateTime',
      valueGetter: ({ data }) => handleDateTime(data.requestDatetime),
    },
    {
      field: 'queueCompletionDatetime',
      headerName: 'Completion DateTime',
      valueGetter: ({ data }) => handleDateTime(data.queueCompletionDatetime),
    },
  ];

  const getLocationsList = async (eve, setFieldValue) => {
    const { name, value } = eve.target;
    if (value) {
      setFieldValue(name, value);
      setFieldValue('locationCode', '');
      axios.post(jwtServiceConfig.locationGetLocations, { agencyCode: value }).then((res) => {
        console.log(res);
        return res.data.responseCode === 0 ? setQueueLocations(res.data.data) : handleResponse(res);
      });
    }
  };

  const getAgencyCodeForQueueManagement = async () => {
    await axios.post(jwtServiceConfig.locationGetAgencyForQM, {}).then((res) => {
      console.log(res);
      return res.data.responseCode === 0 ? setAgencyCodes(res.data.data) : handleResponse(res);
    });
  };

  useEffect(() => {
    // getLocationsList();
    getAgencyCodeForQueueManagement();
  }, []);

  const handleSubmit = async (values) => {
    setShowGrid(false);
    await axios
      .post(jwtServiceConfig.queueGetAllQueueForLocation, {
        locationCode: values.locationCode,
      })
      .then((res) => {
        console.log(res);
        return res.data.responseCode === 0 ? (setRowData(res.data.data), setShowGrid(true)) : handleResponse(res);
      });
  };

  return (
    <>
      <Paper className="relative flex flex-col flex-auto p-24 prounded-2xl shadow overflow-hidden">
        <div className="mt-4 ml-8">
          <div className="flex justify-between">
            <div>
              <Typography className="text-3xl font-semibold tracking-tight leading-8">Queue History Report</Typography>
              <h3>
                <span className="text-md">Queue Management &gt; Queue History Report</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="my-12">
          <Formik initialValues={initialValuesQueueHistoryPage} onSubmit={handleSubmit} validationSchema={validationSchemaQueueHistoryPage} validateOnBlur={false}>
            {({ setFieldValue }) => (
              <Box className="mt-12">
                <Form noValidate>
                  <GridContainer spacing={2}>
                    <GridItem xs={3}>
                      <FormikController control="select" name="agencyCode" label="Agency" required onChange={(e) => getLocationsList(e, setFieldValue)}>
                        {agencyCodes?.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </FormikController>
                    </GridItem>
                    <GridItem xs={3}>
                      <FormikController control="select" name="locationCode" label="Location Code" required disabled={queueLocations?.length === 0}>
                        {queueLocations?.map((option) => (
                          <MenuItem key={option?.locationCode} value={option?.locationCode}>
                            {option?.locationName}
                          </MenuItem>
                        ))}
                      </FormikController>
                    </GridItem>
                    <GridItem xs={3}>
                      <Box className="px-8 py-12">
                        <MuiButton type="submit" disableIsSubmit>
                          Submit
                        </MuiButton>
                      </Box>
                    </GridItem>
                  </GridContainer>
                </Form>
              </Box>
            )}
          </Formik>
        </div>
      </Paper>
      {showGrid && (
        <Paper className="relative flex flex-col flex-auto p-24 prounded-2xl shadow overflow-hidden my-20">
          <div className="mt-16">
            <AgGrid rowData={rowData} columnDefs={columnDefs} overlayNoRowsTemplate="No Data found" />
          </div>
        </Paper>
      )}
    </>
  );
};
export default QueueHistoryPage;
