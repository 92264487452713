import { lazy } from 'react';

const Notification = lazy(() => import('./Notification'));

const NotificationConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/notification',
      element: <Notification />,
    },
  ],
};

export default NotificationConfig;
