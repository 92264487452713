import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const ActivityReport = lazy(() => import('./activity-report/ActivityReport'));
const SearchReport = lazy(() => import('./search-report/SearchReport'));

const AuditLogConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'module/audit-report',
      children: [
        {
          path: '',
          element: <Navigate to="activity-report" />,
        },
        {
          path: 'activity-report',
          element: <ActivityReport />,
        },
        {
          path: 'search-report',
          element: <SearchReport />,
        },
      ],
    },
  ],
};

export default AuditLogConfig;
