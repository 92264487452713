import { AgGridReact } from 'ag-grid-react';
import { InputLabel } from '@mui/material';
import { useCallback, useRef, useMemo, forwardRef } from 'react';

/* Reusable Ag-grid component */

const AgGrid = forwardRef((props, ref) => {
  const { columnDefs, rowData, gridOptions, overlayNoRowsTemplate, onGridReady, treeData, getDataPath, defaultExcelExportParams, excelStyles, autoGroupColumnDef } = props;
  const gridRef = useRef();

  const onPageSizeChanged = useCallback(() => {
    const { value } = document.getElementById('page-size');
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  const autoSizeStrategy = {
    type: 'fitCellContents',
    columnLimits: [
      {
        colId: 'actions',
        minWidth: 500,
      },
    ],
  };

  const defaultColDef = {
    resizable: true,
    autoHeight: true,
    filter: true,
    autoHeaderHeight: true,
    minWidth: 150,
    maxWidth: 500,
    cellClassRules: {
      'cell-wrap-text': (params) => params?.value?.length > 20,
    },
    // menuTabs: ['filterMenuTab'],
    cellDataType: false,
  };

  const onFirstDataRendered = (params) => {
    const allColumnIds = [];
    let totalColumnWidth = 0;
    params.columnApi.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
      totalColumnWidth += params.columnApi.getColumnState().find((c) => c.colId === column.getColId()).width;
    });
    // const gridWidth = document.querySelector('.ag-root').getBoundingClientRect().width;
    const gridWidth = document.querySelector('.ag-body-viewport').clientWidth;
    const isFullWidth = parseInt(totalColumnWidth, 10) >= gridWidth;
    if (isFullWidth && allColumnIds?.length > 5) {
      params.columnApi.autoSizeColumns(allColumnIds);
    } else {
      params.api.sizeColumnsToFit();
    }
  };

  const handleGridReady = (params) => {
    if (onGridReady) onGridReady(params);
    params?.api?.sizeColumnsToFit();
  };

  const onGridSizeChanged = (params) => {
    params.api.sizeColumnsToFit();
  };

  return (
    <div style={containerStyle} className="ag-theme-balham">
      <div className="mt-10 mb-20 flex">
        <InputLabel className="text-sm"> Page Size &nbsp;:&nbsp;</InputLabel>
        <select onChange={onPageSizeChanged} id="page-size">
          <option value="10">10</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="500">500</option>
          <option value="1000">1000</option>
        </select>
      </div>
      <div style={gridStyle}>
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={rowData}
          gridOptions={gridOptions}
          overlayNoRowsTemplate={overlayNoRowsTemplate}
          onGridReady={handleGridReady}
          treeData={treeData}
          autoSizeStrategy={autoSizeStrategy}
          getDataPath={getDataPath}
          defaultExcelExportParams={defaultExcelExportParams}
          excelStyles={excelStyles}
          suppressDragLeaveHidesColumns
          enableCellTextSelection
          autoGroupColumnDef={autoGroupColumnDef}
          defaultColDef={defaultColDef}
          onFirstDataRendered={onFirstDataRendered}
          domLayout="autoHeight"
          pagination
          paginationPageSize={10}
          headerHeight={50}
          rowHeight={35}
          // onGridSizeChanged={(params) => onFirstDataRendered(params)}
          onGridSizeChanged={onGridSizeChanged}
        />
      </div>
    </div>
  );
});

export default AgGrid;
