import { lazy } from 'react';

const GovernmentRevenueCollection = lazy(() => import('./government-revenue-collection/GovernmentRevenueCollection'));
const Dashboard = lazy(() => import('./Dashboard'));
const CollectionDashboard = lazy(() => import('./daily-collection-dashboard/CollectionDashboard'));
const AdminDashboard = lazy(() => import('./admin-dashboard/AdminDashboard'));
const AgencyRevenueDashboard = lazy(() => import('./agency-revenue-dashboard/AgencyRevenueDashboard'));
const CashMovementDashboard = lazy(() => import('./cash-movement-dashboard/CashMovementDashboard'));

const DashboardConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    { path: 'tracking-system-for-cash-flow', element: <Dashboard /> },
    { path: 'admin-dashboard', element: <AdminDashboard /> },
    { path: 'revenue-collection-dashboard', element: <AgencyRevenueDashboard /> },
    { path: 'daily-collection-dashboard', element: <CollectionDashboard /> },
    { path: 'cash-movement-dashboard', element: <CashMovementDashboard /> },
    { path: 'government-revenue-collection', element: <GovernmentRevenueCollection /> },
  ],
};
export default DashboardConfig;
