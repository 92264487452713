import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const CustomerPaymentReport = lazy(() => import('./customer-payment-report/CustomerPaymentReport'));
const BundleReport = lazy(() => import('./bundle-report/BundleReport'));
const BundleAcceptanceReport = lazy(() => import('./bundle-acceptance/BundleAcceptanceReport'));
const ConsolidationReport = lazy(() => import('./consolidation-report/ConsolidationReport'));
const DailyPaymentReport = lazy(() => import('./daily-payment-report/DailyPaymentReport'));
const ForeignExchangeReport = lazy(() => import('./foreign-exchange-reports/ForeignExchangeReports'));
const EFTReport = lazy(() => import('./eft-report/EFTReport'));
const ExchangeMutilatedReport = lazy(() => import('./exchange-mutilated-report/ExchangeMutilatedReport'));
const AbandonedFundReport = lazy(() => import('./abandoned-fund-report/AbandonedFundReport'));
const ComBankDepositReport = lazy(() => import('./com-bank-deposit/ComBankDepositReport'));
const ComBankFundTransferReport = lazy(() => import('./com-bank-fund-transfer/CommercialBankFundTransferReport'));
const AssignConsolidationReport = lazy(() => import('./assign-consolidation-report/AssignConsolidationReport'));
const PartialDepositReport = lazy(() => import('./partial-deposit-report/PartialDepositReport'));
const CRMReport = lazy(() => import('./crm-report/CRMReport'));
const CoinsTransactionReport = lazy(() => import('./coins-transaction-report/CoinsTransactionReport'));
const PettyCashReport = lazy(() => import('./petty-cash-report/PettyCashReport'));
const OtherStaffPymntsReport = lazy(() => import('./other-staff-payments-report/OtherStaffPymntsReport'));

const AdminReportsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'module/report',
      children: [
        {
          path: '',
          element: <Navigate to="customer-payment-report" />,
        },
        {
          path: 'customer-payment-report',
          element: <CustomerPaymentReport />,
        },
        {
          path: 'bundle-report',
          element: <BundleReport />,
        },
        {
          path: 'bundle-acceptance-report',
          element: <BundleAcceptanceReport />,
        },
        {
          path: 'consolidation-report',
          element: <ConsolidationReport />,
        },
        {
          path: 'daily-payment-report',
          element: <DailyPaymentReport />,
        },
        {
          path: 'foreign-exchange-report',
          element: <ForeignExchangeReport />,
        },
        {
          path: 'eft-report',
          element: <EFTReport />,
        },
        {
          path: 'mutilated-notes-report',
          element: <ExchangeMutilatedReport />,
        },
        {
          path: 'abandoned-fund-report',
          element: <AbandonedFundReport />,
        },
        {
          path: 'com-bank-deposit-report',
          element: <ComBankDepositReport />,
        },
        {
          path: 'com-bank-fund-transfer-report',
          element: <ComBankFundTransferReport />,
        },
        {
          path: 'assign-consolidation-report',
          element: <AssignConsolidationReport />,
        },
        {
          path: 'partial-deposit-report',
          element: <PartialDepositReport />,
        },
        {
          path: 'crm-report',
          element: <CRMReport />,
        },
        {
          path: 'coinsreport',
          element: <CoinsTransactionReport />,
        },
        {
          path: 'petty-cash-report',
          element: <PettyCashReport />,
        },
        {
          path: 'other-staff-payments-report',
          element: <OtherStaffPymntsReport />,
        },
      ],
    },
  ],
};

export default AdminReportsConfig;
