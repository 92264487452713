import TransactionPage from './TransactionPage';

const TransactionPageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: true,
        },
        leftSidePanel: {
          display: true,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'txn-success-1',
      element: <TransactionPage />,
    },
  ],
};

export default TransactionPageConfig;
