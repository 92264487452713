import format from 'date-fns/format';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import moment from 'moment';

export const agencyConfig = {
  default: 'ALL',
  master: 'BOB',
  agencyOne: 'OAG',
  agencyTwo: 'BUR',
  agencyThree: 'OGO',
};

export const defaultAgencyValue = 'ALL';
export const currencyCode = '072';
export const defaultMobileNumberAdornment = '+267';
export const agencyCodeList = ['BOB', 'OAG', 'BUR', 'OGO'];
export const defaultCurrenyValue = 'P';
export const firstDayofMonth = new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000);
export const defaultURNLength = 22;
export const defaultRefNumLength = 11;

export const publicKey =
  'MIIBojANBgkqhkiG9w0BAQEFAAOCAY8AMIIBigKCAYEA1c5pDbjnzigZHFmrErcCB7i0J9LPmEGf0UJ3hUAvlkCLcD1sRdvcY8qjWfVxNgGILOvGruMOC0GtCzmNdbF+vnUxFdsAuC88uzzh2Te4DpJ/MwFBHGKruYJpGoBOXZxDQdPcWjxn8kashUR7Wdlj1oCnC102IiFWBTfozUEBb6xszNfvU+9mSZ9j6HB85pDRNRaGeqN8OOuaXVZwchkzNUQ3bSkarC9xE/kSIlIVfqNP4+jp6PMEsn/UEqKIfp1FzWB8uuhN0v48LGYSCJrcrFdv5S6hbUslnfLlKgpJUdtlnewdxdTW3hmODB/zna+juXdtgPIEGBB7PXHYYpohzFMGiehqFJ1uHBee3YBfnty3GZMxA7qwQAmkHqHEHNfuD7SEqSO69N3fRlZOFxKqlbgIxct9vau/uVGrnFC+kNuE29Yblew75PB6GC6MUOeUtE78YMjRwu454CYzBlPZq7yN0vZotJZLDnDT9m9eJMZFpRKOvP6Ifn/smfsYHqhZAgMBAAE=';
export const paymentGetter = (params) => {
  return params?.data?.pymnt_mode === 'CSH' ? 'Cash' : '';
};
export const printReceipt = (value, refNum, canvasRef) => {
  html2canvas(canvasRef.current, {
    scrollY: -window.scrollY,
    scrollX: -window.scrollX,
  }).then((canvas) => {
    const data = canvas.toDataURL('image/png');
    const pdf = new JsPDF({ unit: 'px', format: 'a4' });
    const width = pdf.internal.pageSize.getWidth();
    const height = pdf.internal.pageSize.getHeight();
    const widthRatio = width / canvas.width;
    const heightRatio = height / canvas.height;
    const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

    pdf.addImage(data, 'PNG', 20, 0, canvas.width * ratio, canvas.height * ratio);
    if (value === 'dwld') {
      pdf.save(`Receipt-${refNum ?? undefined}.pdf`);
    } else if (value === 'prnt') {
      // pdf.autoPrint();
      // pdf.output("dataurlnewwindow")
      window.open(pdf.output('bloburl'), '_blank');
    }
  });
};
export const showDecimalValues = (e, props) => {
  let start = 0;
  start = e.target.selectionStart;
  const { name, value } = e.target;
  let val = value;
  val = val.replace(/([^0-9.]+)/, '');
  val = val.replace(/^(0|\.)/, '');
  const match = /(\d{0,10})[^.]*((?:\.\d{0,2})?)/g.exec(val);
  const Tempvalue = match[1] + match[2];
  e.target.value = Tempvalue;
  // props.setFieldValue((prevvalues) => {
  //   return {
  //     ...prevvalues,
  //     [name]: Tempvalue,
  //   };
  // });

  if (val.length > 0) {
    e.target.value = Number(Tempvalue).toFixed(2);
    e.target.setSelectionRange(start, start);
    props.setFieldValue(name, Number(Tempvalue).toFixed(2));
  } else {
    props.setFieldValue(name, Number('000'));
  }
};

const dateFormat = 'YYYY-MM-DD HH:mm:ss.SSS';

const defaultDateFormat = 'MMM dd, yyyy hh:mm';

const dateFormatsArr = ['MM/dd/yyyy h:mm a', 'MM/dd/yyyy', 'd MMMM yyyy', 'yyyy/MM/dd'];

const isValidDateString = (str, frmt) => {
  return moment(str, frmt, true).isValid();
};

export const handleDateTime = (values, param) => {
  if (!values) return null;
  const date = values;
  const getDateFormat = JSON.parse(sessionStorage.getItem('dateFormat'));
  const isFormat = dateFormatsArr?.includes(getDateFormat) ? getDateFormat : defaultDateFormat;
  const isDateAndTime = isFormat.match(/[dD/M/y:hma ]/g).join('');
  const isDateOnly = isFormat.match(/[dD/M/y ]/g).join('');
  const isTimeOnly = isFormat.match(/[h:ma ]/g)?.join('');

  if (isValidDateString(date, dateFormat)) {
    if (date && param === 'T') {
      // return format(new Date(date), 'hh:mm');
      return format(new Date(date), isTimeOnly);
    }
    if (date && param === 'D') {
      // return format(new Date(date), 'MMM dd, yyyy');
      return format(new Date(date), isDateOnly);
    }
    // return format(new Date(date), 'MMM dd, yyyy hh:mm');
    return format(new Date(date), isDateAndTime);
  }
  return 'NA';
};

export function calculateSize(size) {
  if (size.length >= 4 && size.length < 7) {
    const value = size / 1000;
    return `${Number(value).toFixed(3)} KB`;
  }
  if (size.length >= 7) {
    const value = size / 1000000;
    return `${Number(value).toFixed(3)} MB`;
  }
  return `${size} B`;
}

export const formatTime = (values) => {
  if (values && values !== null) {
    const responseTime = new Date(values);
    const currTime = new Date();
    const timeDiff = currTime - responseTime;
    // console.log(timeDiff);
    // console.log(timeDiff / (1000 * 60));
    // const min = Math.floor(timeDiff / (1000 * 60));
    // console.log(Math.floor(min / 60));
    const min = Math.floor(timeDiff / (1000 * 60));
    // console.log(min);
    if (min) {
      if (min > 1439) {
        const time = Math.floor(min / 1440);
        return time === 1 ? `${time} day ago` : `${time} days ago`;
      }
      if (min > 59) {
        const time = Math.floor(min / 60);
        return time === 1 ? `${time} hr ago` : `${time} hrs ago`;
      }
      if (min < 59) {
        const time = min;
        return `${time} min ago`;
      }
      return null;
    }
    return 'Just now';

    // return format(new Date(values), "hh:mm");
  }
  return '';
};

export function transactionTypeName(value) {
  if (value) {
    switch (value) {
      case '001':
        return 'Revenue Collection-Cash';
      case '002':
        return 'Revenue Collection-Card';
      case '003':
        return 'Revenue Collection-ECommerce';
      case '004':
        return 'Revenue Collection-EFT';
      case '005':
        return 'Revenue Collection-RTGS';
      case '010':
        return 'Bundle Cash';
      case '020':
        return 'Bundle Cash Deposit';
      case '030':
        return 'Consolidate Cash';
      case '040':
        return 'Deposit Consolidated Cash';
      case '050':
        return 'Commercial Bank Transfer';
      case '061':
        return 'Staff Payment Deposit';
      case '062':
        return 'Staff Payment Withdrawal';
      case '071':
        return 'Public Payment Deposit';
      case '072':
        return 'Public Payment Withdrawal';
      default:
        return 'NA';
    }
  }
  return undefined;
}

export function displayCurrencyValue(params) {
  // const value = params.data.txnAmount;
  return `P ${params?.data?.txnAmount.toFixed(2)}`;
  // if (value !== undefined) {
  //   if (value?.includes(".")) {
  //     return value;
  //   } else if (value?.length <= 1) {
  //     return `0.0${value} `;
  //   } else if (value?.length <= 2) {
  //     return `0.${value} `;
  //   } else {
  //     return value?.slice(0, -2) + "." + value?.slice(-2);
  //   }
  // }
}

export const showStageName = (value) => {
  if (value || value === null) {
    switch (value) {
      case null:
        return 'Customer Collection';
      case 'F':
        return 'Customer Collection';
      case 'B':
        return 'Bundled';
      case 'R':
        return 'Deposit RO';
      case 'C':
        return 'Consolidated';
      case 'N':
        return 'Deposit CB';
      case 'T':
        return 'Deposited';
      case 'P':
        return 'Partial Deposit';
      case 'D':
        return 'Withdraw';
      case 'Z':
        return 'Staff Payment';
      case 'Y':
        return 'Department';
      case 'X':
        return 'Public Payment';
      default:
        return 'NA';
    }
  }
  return 'NA';
};

export const showAgencyName = (value) => {
  if (value === 'BOB') {
    return 'Bank of Botswana';
  }
  if (value === 'OAG') {
    return 'Office of Accountant General';
  }
  if (value === 'BUR') {
    return 'Botswana Unified Revenue Services';
  }
  if (value === 'OGO') {
    return 'One Gov';
  }
  return value;
};

const checkDefaultAgency = (agency) => {
  return agency === defaultAgencyValue;
};

export const renameAgency = (value, isFullName) => {
  if (isFullName === true && value) {
    return checkDefaultAgency(value) === true ? 'Bank of Botswana' : value;
  }
  return checkDefaultAgency(value) === true ? 'BANK' : value;
};

export function checkisIntlValLength(val) {
  return val?.length > 0 ? 'All' : '';
}

export const showAmountInMillion = (v) => {
  if (v) {
    const data = v.split('.');
    return data[0].length < 8
      ? `${defaultCurrenyValue} ${Number(v).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
      : `${defaultCurrenyValue} ${Number(parseInt(v / 1000000, 10)).toLocaleString('en-US')} M`;
  }
  return null;
};

export function displayCurrencyFormat(amount) {
  const getCurrValue = JSON.parse(sessionStorage.getItem('currencyValues'));
  if (getCurrValue) {
    return amount
      ? `${getCurrValue} ${amount?.toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
      : `${getCurrValue} 0.00`;
  }
  return amount;
}

export const showAmountInFormat = (v) => {
  const getCurrValue = JSON.parse(sessionStorage.getItem('currencyValues'));
  if (getCurrValue) {
    if (v) {
      const val = Number(v).toFixed(2);
      const value = val.split('.');
      if (value[0].length >= 7) {
        return `${getCurrValue} ${Number(parseInt(v, 10) / 1000000).toLocaleString('en-US', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })} M`;
      }
      if (value[0].length < 7 && value[0].length >= 4) {
        return `${getCurrValue} ${Number(parseInt(v, 10) / 1000).toLocaleString('en-US')} K`;
      }
      if (value[0].length <= 3) {
        return `${getCurrValue} ${Number(value[0])}.${value[1]}`;
      }
    } else {
      return `${getCurrValue} 0.00`;
    }
  } else {
    return '0.00';
  }
  return null;
};

export function convertDateForApiBody(value) {
  return value && moment(value).format('YYYY-MM-DD');
}

export const handleRemoveCommasFromCurrency = (values) => {
  let val;
  if (values) {
    val = values?.toString().includes(',') ? values?.replace(/,/g, '') : values;
  }
  return val;
};

export const defaultCurrencyKeys = ['txn_amount'];
export const defaultDateKeys = ['datetime', 'completed_datetime', 'created_datetime'];

export const agDefaultAmtColDef = { minWidth: 140, cellClass: 'excelCurrencyFormatter' };

export const getUserIdType = (state, key, value) => {
  if (state?.length > 0 && value && key) {
    const getIdType = state?.find((item) => item[key] === value);
    if (getIdType[key]) {
      return getIdType[key];
    }
    return value;
  }
  return value;
};
