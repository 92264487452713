import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import ForgotPasswordConfig from '../main/password-modules/forgot-password/ForgotPasswordConfig';
import ChangePasswordConfig from '../main/password-modules/change-password/ChangePasswordConfig';
import Error404Page from '../main/404/Error404Page';
import DashboardConfig from '../main/dashboard/DashboardConfig';
import UserRoleConfig from '../main/user-role-management/UserRolePageConfig';
import QueueManagementConfig from '../main/queue-management/QueueManagementConfig';
import MastersManagementConfig from '../main/master-management/MasterManagementConfig';
import OAGConfig from '../main/oag/OAGConfig';
import TransactionPageConfig from '../main/transaction-page/TransactionPageConfig';
import PublicPaymentsConfig from '../main/public-and-staff-payments/PublicPaymentsConfig';
// import BURConfig from '../main/bur/BURConfig';
import AuditLogConfig from '../main/audit-logs/AuditLogsConfig';
import AdminReportsConfig from '../main/admin-reports/AdminReportsConfig';
import ForceChangePasswordConfig from '../main/password-modules/force-change-password/ForceChangePasswordConfig';
import profileAppConfig from '../main/profile/ProfileAppConfig';
import NotificationConfig from '../main/notification/NotificationConfig';
import BankEmployeeServicesConfig from '../main/public-and-staff-payments/BankEmployeeServicesConfig';
import BankEmployeeServiceManagementConfig from '../main/public-and-staff-payments/BankEmployeeServiceManageConfig';
import FileReconcilationConfig from '../main/file-reconcilation/FileReconcilationConfig';
import TellerAssistedServicesConfig from '../main/teller-assisted-services/TellerAssistedServicesConfig';

/* Routes config file
 * The file contains all the routes of the app.
 * Here authentication property is added for each route
 */

const routeConfigs = [
  DashboardConfig,
  SignOutConfig,
  SignInConfig,
  ForgotPasswordConfig,
  ChangePasswordConfig,
  UserRoleConfig,
  MastersManagementConfig,
  QueueManagementConfig,
  OAGConfig,
  TransactionPageConfig,
  PublicPaymentsConfig,
  AuditLogConfig,
  AdminReportsConfig,
  ForceChangePasswordConfig,
  profileAppConfig,
  NotificationConfig,
  BankEmployeeServicesConfig,
  BankEmployeeServiceManagementConfig,
  FileReconcilationConfig,
  TellerAssistedServicesConfig,
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <Navigate to="/module/user-management/users" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '404',
    element: <Error404Page />,
  },
  {
    path: '*',
    element: <Navigate to="sign-in" />,
  },
];

export default routes;
