import * as React from 'react';
import { useEffect, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { showMessage } from 'app/store/fuse/messageSlice';
import { logoutUser, setUser } from 'app/store/userSlice';
import { openDialog } from 'app/store/fuse/dialogSlice';
import routes from 'app/configs/routesConfig';
import { showDisableLoader } from 'app/store/loaderSlice';
import navigationConfig from 'app/configs/navigationConfig';
import { setAppConfig } from 'app/store/appConfigSlice';
import { resetSessionRedirectUrl } from '@fuse/core/FuseAuthorization/sessionRedirectUrl';
import _ from 'lodash';
import jwtService from './services/jwtService';

const AuthContext = React.createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const [dashboardPermID] = useState(['P00018', 'P00014', 'P00006', 'P00012', 'P00180']);

  const dispatch = useDispatch();

  const authenticated = useMemo(() => {
    return { isAuthenticated };
  }, [isAuthenticated]);

  useEffect(() => {
    function success(user, message) {
      if (message) {
        dispatch(showMessage({ message }));
      }

      Promise.all([
        dispatch(setUser(user)),
        // You can receive data in here before app initialization
      ]).then(() => {
        setWaitAuthCheck(false);
        setIsAuthenticated(true);
      });
    }

    function pass(message) {
      if (message) {
        dispatch(showMessage({ message }));
      }

      setWaitAuthCheck(false);
      setIsAuthenticated(false);
    }

    jwtService.on('currency', (res) => {
      if (res?.data?.responseCode === 0) {
        dispatch(setAppConfig(res?.data?.data));
      }
      dispatch(
        openDialog({
          response: {
            error: true,
            title: res.message,
            message: res.errors[0].message,
          },
        })
      );
    });
    // show/Hide loader
    jwtService.on('showLoader', (showLoader) => {
      return showLoader ? dispatch(showDisableLoader()) : dispatch(showDisableLoader());
    });

    // regenerate  token for unauthrorized requests
    jwtService.on('regenerateToken', (isMultipleReq) => {
      if (!isMultipleReq) {
        setWaitAuthCheck(true);

        return jwtService
          .regenerateToken()
          .then(() => setWaitAuthCheck(false))
          .catch(() => {
            pass();
          });
      }
      return setWaitAuthCheck(true);
    });

    jwtService.on('regeneratedToken', (originalRequest) => {
      return jwtService.callRegenerateToken(originalRequest);
    });

    // Axios error handling
    jwtService.on('notFoundError', (error) => {
      console.log('notFoundError', error);
      if (error !== null && error.message) {
        dispatch(
          openDialog({
            response: {
              error: true,
              title: `${error} ${error.code ? `- ${error.code}` : ''}`,
              message: `Service is not reachable. Please try again later. (${error?.message})`,
            },
          })
        );
      }

      pass();
    });
    jwtService.on('serverError', (error) => {
      console.log('serverError', error);
      if (error !== null && error.message) {
        dispatch(
          openDialog({
            response: {
              error: true,
              title: `${error} ${error.code ? `- ${error.code}` : ''}`,
              message: `Service is not responding as expected. Please try again later. (${error?.message})`,
            },
          })
        );
      }
      pass();
    });
    jwtService.on('axiosError', (error) => {
      console.log('axiosError', error);
      if (error) {
        dispatch(
          openDialog({
            response: {
              error: true,
              title: `Error`,
              message: `Something went wrong, Please try again later `,
            },
          })
        );
      } else {
        dispatch(
          openDialog({
            response: {
              error: true,
              title: `Error`,
              message: `Something went wrong, Please try again later`,
            },
          })
        );
      }
      pass();
    });
    jwtService.on('onAutoLogin', () => {
      /**
       * Sign in and retrieve user data with stored token
       */
      jwtService
        .signInWithToken()
        .then()
        .catch((error) => {
          const msg = typeof error === 'string' ? error : error[0]?.message;
          pass(msg);
          dispatch(logoutUser());
          resetSessionRedirectUrl();
        });
    });

    function noPermissionsAssigned() {
      dispatch(logoutUser());
      pass('Authentication Failed, Please contact the administrator');
      resetSessionRedirectUrl();
      sessionStorage.removeItem('jwt_access_token');
      sessionStorage.removeItem('jwt_refresh_token');
    }

    jwtService.on('onLogin', async (user) => {
      const { rolePermissions } = user;
      function mapRoleToRoutes(userDetails) {
        const { roleName } = userDetails ?? {};
        if (rolePermissions && roleName) {
          const avlblPermPath = [];
          navigationConfig.map((navItem) => {
            if (navItem?.children && navItem?.children?.length > 0) {
              navItem?.children?.map((navSecItem) => {
                rolePermissions.map((perm) => {
                  if (perm.permissionId === navSecItem.id) {
                    avlblPermPath.push(navSecItem);
                  }
                  return perm;
                });
                return navSecItem;
              });
            }
            return navItem;
          });
          // Adding Role to other default routes as well
          const defaultPaths = ['apps/notification', 'apps/profile'];
          if (avlblPermPath.length > 0) {
            avlblPermPath?.map((perm) => {
              routes.forEach((route) => {
                if (route?.auth?.length > 0) {
                  if (defaultPaths.includes(route.path)) {
                    if (!_.isEqual(route.auth, Array.of(roleName))) {
                      route.auth = Array.of(roleName);
                    }
                  }

                  if (perm?.type === 'collapse') {
                    if (perm?.children[0]?.url?.split('/')?.splice(0, 2)?.join('/') === route.path) {
                      route.auth = Array.of(roleName);
                    }
                  }
                  if (perm?.type === 'item') {
                    if (perm?.url === route?.path) {
                      route.auth = Array.of(roleName);
                    }
                  }
                }
              });
              return perm;
            });
          }
        }
      }

      function checkDashboardPermission(perm, isUser) {
        const dashboardPerm = perm.find((item) => dashboardPermID?.includes(item.permissionId));
        if (dashboardPerm) {
          let loginRedirectUrl;
          navigationConfig.map((item) => {
            if (item?.children) {
              item.children.map((secItem) => {
                if (secItem.id === dashboardPerm.permissionId) {
                  loginRedirectUrl = secItem.url;
                }
                return secItem;
              });
            }
            return item;
          });
          if (loginRedirectUrl) {
            user = { ...isUser, loginRedirectUrl };
            mapRoleToRoutes(user);
            success(user);
            return true;
          }
          return false;
        }
        return false;
      }

      // Authentication and addding role to routes.

      if (rolePermissions?.length > 0 && rolePermissions !== null) {
        if (checkDashboardPermission(rolePermissions, user)) {
          return null; // Success will handle in checkDashboardPermission function
        }
        const parentPerm = rolePermissions.filter((perm) => perm.level === 0);

        if (parentPerm) {
          const matchedPathsCollection = navigationConfig[1].children.filter((nav) => parentPerm.some((perm) => perm.permissionId === nav.id));
          if (matchedPathsCollection) {
            const finalPathCollection = [];
            matchedPathsCollection.map((navFirst) => {
              if (navFirst.children) {
                navFirst.children.map((navSec) => {
                  parentPerm.map((perm) => {
                    if (perm.children) {
                      perm.children.forEach((secPerm) => {
                        if (secPerm === navSec.id) {
                          finalPathCollection.push(navSec);
                        }
                      });
                    }
                    return perm;
                  });
                  return navSec;
                });
              } else {
                finalPathCollection.push(navFirst);
              }
              return navFirst;
            });

            if (finalPathCollection.length > 0) {
              let loginRedirectUrl;
              // Assign login redirectUrl
              finalPathCollection.every((item) => {
                if (item?.url) {
                  loginRedirectUrl = item?.url;
                  return false;
                }
                return true;
              });
              user = { ...user, loginRedirectUrl };

              await mapRoleToRoutes(user);
              success(user);
            }
          } else {
            noPermissionsAssigned();
          }
        } else {
          noPermissionsAssigned();
        }
      } else {
        noPermissionsAssigned();
      }
      return user;
    });

    jwtService.on('onLogout', () => {
      pass();

      dispatch(logoutUser());
    });

    jwtService.on('onAutoLogout', () => {
      pass();
      setTimeout(() => dispatch(logoutUser()), 2000);
    });

    jwtService.on('onNoAccessToken', () => {
      pass();
    });

    jwtService.init();
  }, [dispatch, dashboardPermID]);

  return waitAuthCheck ? <FuseSplashScreen /> : <AuthContext.Provider value={authenticated}>{children}</AuthContext.Provider>;
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
