import { Card, Typography, Menu, MenuItem, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { motion } from 'framer-motion';
import axios from 'axios';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { handleResponse } from 'app/shared-components/index';
import MuiButton from 'app/shared-components/mui/button/MuiButton';
import { useState } from 'react';
import MuiDialog from 'app/shared-components/mui/dialog/MuiDialog';
import QueueDetails from './QueueDetails';

const container = {
  show: {
    transition: {
      staggerChildren: 0.1,
    },
  },
};
const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};
const DistributedQueueDetails = ({ queueDetails, user, getGeneratedQueueForLocation }) => {
  const [pageControl, setPageControl] = useState({
    isOpen: false,
    selectedQueue: '',
  });
  const [contextMenu, setContextMenu] = useState(null);
  const { locationCode } = user;

  if (!queueDetails) {
    return null;
  }

  const removeQueue = () => {
    axios
      .post(jwtServiceConfig.queueRemoveQueue, {
        queueNumber: pageControl.selectedQueue,
        locationCode,
      })
      .then((res) => {
        if (res.data.responseCode === 0) {
          setPageControl((prev) => ({ ...prev, isOpen: false, selectedQueue: '' }));
          getGeneratedQueueForLocation();
          handleResponse(res);
        } else {
          handleResponse(res);
        }
      });
  };

  const handleCloseQueueDltDialog = () => {
    setPageControl((prev) => ({
      ...prev,
      isOpen: false,
      selectedQueue: '',
    }));
  };

  return (
    <>
      <Card className="mt-20 p-20">
        <motion.div variants={container} initial="hidden" animate="show" className="grid grid-flow-col auto-cols-max gap-20 mt-20 md:mt-8 overflow-auto">
          {queueDetails.length === 0 && <Typography className="text-md leading-tight">Waiting list queue numbers will be shown here.</Typography>}
          {queueDetails?.map((queue) => (
            <motion.div
              key={queue?.queueNumber}
              variants={item}
              style={{ cursor: 'context-menu' }}
              className="w-[220px] min-w-full sm:min-w-100 h-full hover:cursor-pointer"
              onContextMenu={(e) => {
                e.preventDefault();
                setContextMenu(
                  contextMenu === null
                    ? {
                        mouseX: e.clientX + 2,
                        mouseY: e.clientY - 6,
                      }
                    : null
                );
                setPageControl((prev) => ({ ...prev, selectedQueue: queue?.queueNumber }));
              }}
            >
              <QueueDetails queue={queue} />
            </motion.div>
          ))}
        </motion.div>
      </Card>
      <Menu
        open={contextMenu !== null}
        onClose={() => setContextMenu(null)}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
      >
        <MenuItem
          onClick={() => {
            setContextMenu(null);
            setPageControl((prev) => ({
              ...prev,
              isOpen: true,
            }));
          }}
        >
          Remove Queue Number
        </MenuItem>
      </Menu>
      <MuiDialog open={pageControl.isOpen}>
        <DialogTitle>
          <Typography className="text-xl font-semibold tracking-tight leading-8">Remove Queue</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography className="text-16 font-semibold tracking-tight">Are you sure want to remove this Queue Number?</Typography>
        </DialogContent>
        <DialogActions>
          <div className="flex">
            <MuiButton type="cancel-btn" className="mx-8" onClick={handleCloseQueueDltDialog}>
              Cancel
            </MuiButton>
            <MuiButton type="basic" onClick={() => removeQueue()}>
              Confirm
            </MuiButton>
          </div>
        </DialogActions>
      </MuiDialog>
    </>
  );
};

export default DistributedQueueDetails;
