/* eslint-disable react/jsx-props-no-spreading */
import { TextField } from '@mui/material';
import { ErrorMessage, Field } from 'formik';
/** DropDown
 * Mui TextField with select prop component
 */

const DropDown = (props) => {
  const { name, children, errors, touched, ...rest } = props;
  return (
    <Field as={TextField} name={name} fullWidth select autoComplete="off" variant="outlined" helperText={<ErrorMessage name={name} />} error={Boolean(errors[name] && touched[name])} {...rest}>
      {children}
    </Field>
  );
};

export default DropDown;
