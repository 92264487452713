import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { jwtServiceConfig } from '../auth/services/jwtService/jwtServiceConfig';

export const setCurrency = createAsyncThunk('currency/setCurrency', async () => {
  const res = await axios.post(
    jwtServiceConfig.masterGetCountryDetails,
    {
      countryCode: 'BW',
    },
    { headers: { agencyCode: 'BOB' } }
  );
  console.log(res.data);
  sessionStorage.setItem('currencyValues', JSON.stringify(res.data.data));
  return res.data.data;
});

const currencySlice = createSlice({
  name: 'currency',
  initialState: null,
  extraReducers: {
    [setCurrency.fulfilled]: (state, action) => action.payload,
  },
});

export const selectCurrency = ({ currency }) => currency;

export default currencySlice.reducer;
