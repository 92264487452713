/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-no-constructed-context-values */
// import createGenerateClassName from '@mui/styles/createGenerateClassName';
// import jssPreset from '@mui/styles/jssPreset';
// import { create } from 'jss';
// import jssExtend from 'jss-plugin-extend';
// import rtl from 'jss-rtl';
import Provider from 'react-redux/es/components/Provider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StyledEngineProvider } from '@mui/material/styles';
import routes from 'app/configs/routesConfig';
import ErrorBoundary from '@fuse/utils/ErrorBoundary';
import store from './store';
import AppContext from './AppContext';
import SimpleBackdropLoader from './theme-layouts/layout1/components/SimpleBackdropLoader';

const withAppProviders = (Component) => (props) => {
  function WrapperComponent() {
    return (
      <ErrorBoundary>
        <AppContext.Provider
          value={{
            routes,
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Provider store={store}>
              <StyledEngineProvider injectFirst>
                <SimpleBackdropLoader />
                <Component {...props} />
              </StyledEngineProvider>
            </Provider>
          </LocalizationProvider>
        </AppContext.Provider>
      </ErrorBoundary>
    );
  }

  return WrapperComponent;
};

export default withAppProviders;
