import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { ErrorMessage, Field, Form, Formik, getIn } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
//
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
//
import { useDispatch } from 'react-redux';
import { openDialog } from 'app/store/fuse/dialogSlice';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { logoutUser } from 'app/store/userSlice';
//
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import { selectAppConfig } from 'app/store/appConfigSlice';
import IconButton from '@mui/material/IconButton';
import { getPwdConfig, getValidationSchema } from '../helperFunctionPasswordModule';

function ForceChangePassword() {
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [pwdConfig, setPwdConfig] = useState(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(document.location.search);

  const token = encodeURI(searchParams.get('token'));
  useEffect(() => {
    setTimeout(() => {
      const getPwdLength = getPwdConfig();
      if (getPwdLength) {
        setPwdConfig(Number(getPwdLength));
      } else {
        setPwdConfig(6);
      }
    }, 2000);
    // }
  }, []);

  const handlePreventCopyPaste = (e) => {
    e.preventDefault();
  };

  const validationSchema = useMemo(() => {
    return getValidationSchema(pwdConfig);
  }, [pwdConfig]);

  const handleSubmit = async (values, props) => {
    delete axios.defaults.headers.common.Authorization;
    const data = {
      newPassword: values.password,
      token,
    };
    console.log(data);
    const res = await axios.post(jwtServiceConfig.changePassword, data);
    console.log(res);
    if (res.data.responseCode === 0) {
      dispatch(
        openDialog({
          response: {
            title: res.data.message,
            message: res.data.data.message,
          },
        })
      );
      setTimeout(() => {
        dispatch(logoutUser());
        navigate('/sign-in');
      }, 2000);
    } else {
      dispatch(
        openDialog({
          response: {
            error: true,
            title: res.data.message,
            message: res.data.errors[0].message,
          },
        })
      );
    }
    props.resetForm();
  };
  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">Change password</Typography>
          <Formik
            initialValues={{
              password: '',
              confirmPassword: '',
            }}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({ errors, touched }) => (
              <Form className="flex flex-col justify-center w-full mt-32" noValidate>
                <Grid>
                  <Field
                    as={TextField}
                    name="password"
                    label="Password"
                    fullWidth
                    type={showPassword?.password === true ? 'text' : 'password'}
                    required
                    onCut={handlePreventCopyPaste}
                    onCopy={handlePreventCopyPaste}
                    onPaste={handlePreventCopyPaste}
                    // type="password"
                    className="mb-24"
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowPassword((prev) => ({ ...prev, password: !prev.password }))} onMouseDown={(e) => e.preventDefault()} edge="end">
                            {showPassword.password ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="outlined"
                    helperText={<ErrorMessage name="password" />}
                    error={Boolean(getIn(errors, 'password') && getIn(touched, 'password'))}
                  />
                </Grid>
                <Grid>
                  <Field
                    as={TextField}
                    name="confirmPassword"
                    label="Confirm Password"
                    fullWidth
                    required
                    onCut={handlePreventCopyPaste}
                    onCopy={handlePreventCopyPaste}
                    onPaste={handlePreventCopyPaste}
                    type={showPassword?.confirmPassword === true ? 'text' : 'password'}
                    className="mb-24"
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              setShowPassword((prev) => ({
                                ...prev,
                                confirmPassword: !prev.confirmPassword,
                              }))
                            }
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword.confirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={<ErrorMessage name="confirmPassword" />}
                    error={Boolean(getIn(errors, 'confirmPassword') && getIn(touched, 'confirmPassword'))}
                  />
                </Grid>
                <Button variant="contained" color="secondary" className=" w-full mt-4" aria-label="Register" type="submit" size="large">
                  Change Password
                </Button>
                <Typography className="mt-32 text-md font-medium" color="text.secondary">
                  <span>Return to</span>
                  <Link className="ml-4" to="/sign-in">
                    sign in
                  </Link>
                </Typography>
              </Form>
            )}
          </Formik>
        </div>
      </Paper>
    </div>
  );
}

export default ForceChangePassword;
