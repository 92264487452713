import { Dialog } from '@mui/material';
// To be deleted
export default function CustomDialogBox({ open, onClose, children, width, props }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: width,
            minWidth: width,
          },
        },
      }}
      children={children}
      {...props}
    />
  );
}

CustomDialogBox.defaultProps = {
  width: '500px',
};
