import FileReconcilation from './FileReconcilation';

const FileReconcilationConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'module/file-reconciliation',
      element: <FileReconcilation />,
    },
  ],
};

export default FileReconcilationConfig;
