import React, { useState } from 'react';
import AgGrid from 'app/shared-components/ag-grid/AgGrid';
import axios from 'axios';
//
import { Paper, Box, Typography, MenuItem } from '@mui/material';
import { Formik } from 'formik';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { useSelector } from 'react-redux';
//
import { selectUser } from 'app/store/userSlice';
import { usePermHook } from 'src/app/custom-hooks/user-permission-hook/userPermHook';
import { permissions } from 'src/app/custom-hooks/user-permission-hook/permissions';
import MuiFormDialog from 'app/shared-components/mui/dialog/MuiFormDialog';
import GridContainer from 'app/shared-components/mui/grid/GridContainer';
import GridItem from 'app/shared-components/mui/grid/GridItem';
import FormikController from 'app/shared-components/formik-controller/FormikController';
import { handleDateTime } from 'app/shared-components/page-utils/pageUtils';
import MuiButton from 'app/shared-components/mui/button/MuiButton';
import ActionRendererComponent from 'app/shared-components/action-render/ActionRendererComponent';
import { handleResponse } from 'app/shared-components/index';
import { initialValuesLocationPage, validationSchemaLocationPage } from '../helperFuntionQueueManagement';

/* Queue Management Location Configuration
 * The Bank admin can create multiple locations for each agency.
 * The created location's are used to create a terminal in the terminal page.
 * There is an option to Enable/Disable location.
 */

const LocationPage = () => {
  const [locationPageControl, setLocationPageControl] = useState({
    isOpenDialog: false,
    isStatusDialog: false,
    isEdit: false,
    selectedValues: undefined,
  });
  const [rowData, setRowData] = useState(undefined);
  const [agency, setAgency] = useState(undefined);
  const [location, setLocation] = useState([]);
  const user = useSelector(selectUser);
  const { permission } = usePermHook(permissions.queueManagement.locationPage);
  //
  const locationDialogTitle = locationPageControl?.isEdit ? 'Edit Location' : 'Add Location';
  const locationStatusDialogTitle = locationPageControl?.selectedValues?.isDeleted === 'N' ? 'Activate Location' : 'Inactive Location';

  async function getAllLocationsList(code) {
    const res = await axios.post(jwtServiceConfig.masterGetAllLocationCodesAgencywise, {
      code,
    });
    if (res?.data?.responseCode === 0) {
      setLocation(res?.data?.data);
    } else {
      handleResponse(res);
    }
  }

  const getAllAgencies = async () => {
    const res = await axios.post(jwtServiceConfig.bobGetAllAgencyCode, {
      agencyCode: user?.agencyCode,
    });
    if (res?.data?.responseCode === 0) {
      setAgency(res?.data?.data);
    } else {
      handleResponse(res);
    }
  };

  const handleView = async (data) => {
    await getAllAgencies();
    const selectedLocation = [{ locationCode: data?.locationCode, locationName: data?.locationName }];
    setLocation(selectedLocation);
    setLocationPageControl((prev) => ({ ...prev, selectedValues: data, isOpenDialog: true, isEdit: true }));
  };

  const columnDefs = [
    {
      field: 'locationName',
      headerName: ' Name',
    },
    {
      field: 'locationCode',
      headerName: ' Code',
    },
    {
      field: 'agencyCode',
      headerName: 'Agency',
    },
    {
      field: 'isDeleted',
      headerName: 'Status',
      valueGetter: (params) => {
        if (params?.data?.isDeleted === 'N') {
          return 'Active';
        }
        return 'Inactive';
      },
    },
    {
      headerName: 'Created DateTime',
      valueGetter: ({ data: { createdDatetime } }) => handleDateTime(createdDatetime),
    },
    {
      field: 'createdBy',
      headerName: 'Created by',
    },
    {
      headerName: 'Modified DateTime',
      valueGetter: ({ data: { lastUpdatedDatetime } }) => handleDateTime(lastUpdatedDatetime),
    },
    {
      field: 'lastUpdatedBy',
      headerName: 'Modified by',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      filter: false,
      resizable: false,
      editable: false,
      sortable: false,
      cellRenderer: ({ data }) => ActionRendererComponent('isEdit', data, handleView),
    },
  ];

  const getLocationList = async () => {
    const res = await axios.post(jwtServiceConfig.locationGetLocations, {});
    console.log(res?.data?.data);
    if (res?.data?.responseCode === 0) {
      setRowData(res?.data?.data);
    } else {
      handleResponse(res);
    }
  };

  const getLocationForAgency = async (e, setFieldValue) => {
    const { value } = e.target;
    if (value) {
      setFieldValue('locationName', '');
      setFieldValue('locationCode', '');
      setFieldValue('agencyCode', value);
      await getAllLocationsList(value);
    }
  };

  const handleChangeLocationStatus = async () => {
    const data = {
      locationId: locationPageControl?.selectedValues?.id,
      isDeleted: locationPageControl?.selectedValues.isDeleted,
      agencyCode: locationPageControl?.selectedValues.agencyCode,
      instnId: locationPageControl?.selectedValues.institutionId,
    };
    console.log(data);
    const res = await axios.post(jwtServiceConfig.locationDeleteLocation, data);
    console.log(res);
    if (res?.data?.responseCode === 0) {
      handleResponse(res);
      setLocationPageControl((prev) => ({ ...prev, isStatusDialog: false, isOpenDialog: false, isEdit: false }));
      getLocationList();
    } else {
      handleResponse(res);
      setLocationPageControl((prev) => ({ ...prev, selectedValues: { ...prev?.selectedValues, isDeleted: prev?.selectedValues?.isDeleted === 'Y' ? 'N' : 'Y' } }));
    }
  };

  React.useEffect(() => {
    getLocationList();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    if (locationPageControl?.isEdit === false) {
      const data = {
        agencyCode: values.agencyCode,
        locationCode: values?.locationCode,
        locationName: values.locationName,
        address: '',
        city: '',
        state: '',
        country: '',
      };

      console.log(data);
      const res = await axios.post(jwtServiceConfig.locationAdd, data);

      if (res?.data?.responseCode === 0) {
        handleResponse(res);
        setLocationPageControl((prev) => ({ ...prev, isOpenDialog: false, isEdit: false }));
        setLocation([]);
        getLocationList();
        resetForm();
      } else {
        handleResponse(res);
      }
    } else {
      setLocationPageControl((prev) => ({ ...prev, isOpenDialog: false, isEdit: false }));
      // setLocation([]);
      getLocationList();
      resetForm();
    }
  };
  const filterLcnCodeFromLcnName = (lcnName) => {
    const lcnCode = location?.find((lcn) => lcn?.locationName === lcnName);
    if (lcnCode?.locationCode) return lcnCode?.locationCode;
    return '';
  };

  const closeLocationDialog = async (resetForm) => {
    await setLocationPageControl((prev) => ({ ...prev, isOpenDialog: false, isEdit: false, selectedValues: undefined }));
    resetForm();
  };

  const handleCloseLocationStatusDialog = () => {
    setLocationPageControl((prev) => ({
      ...prev,
      isStatusDialog: false,
      selectedValues: { ...prev?.selectedValues, isDeleted: prev?.selectedValues?.isDeleted === 'Y' ? 'N' : 'Y' },
    }));
  };

  const addLocation = async () => {
    setLocation([]);
    await getAllAgencies();
    setLocationPageControl((prev) => ({ ...prev, isOpenDialog: true, isEdit: false }));
  };

  return (
    <Paper className="relative flex flex-col flex-auto p-24 prounded-2xl shadow overflow-hidden">
      <Box className="mt-4 ml-8 flex flex-row justify-between">
        <Box>
          <Typography className="text-3xl font-semibold tracking-tight leading-8">Location Configuration</Typography>
          <Box>
            <Box>
              <span className="text-md">Queue Management &gt; Location Configuration</span>
            </Box>
          </Box>
        </Box>
        {permission?.P00215 && (
          <Box className="mt-24">
            <MuiButton type="basic" onClick={addLocation}>
              Add
            </MuiButton>
          </Box>
        )}
      </Box>
      <Box className="mt-16">
        <AgGrid rowData={rowData} columnDefs={columnDefs} overlayNoRowsTemplate="No location configured, please configure location" />
      </Box>
      <Formik
        initialValues={locationPageControl?.isEdit ? locationPageControl?.selectedValues : initialValuesLocationPage}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchemaLocationPage}
        validateOnBlur={false}
      >
        {({ resetForm, setFieldValue, handleChange }) => (
          <MuiFormDialog open={locationPageControl?.isOpenDialog} dialogTitle={locationDialogTitle} onClose={() => closeLocationDialog(resetForm)}>
            <GridContainer spacing={2}>
              <GridItem xs={12}>
                <FormikController control="select" name="agencyCode" label="Agency" disabled={locationPageControl?.isEdit} onChange={(e) => getLocationForAgency(e, setFieldValue, resetForm)} required>
                  {agency?.map((option) => (
                    <MenuItem key={option?.agencyCode} value={option?.agencyCode}>
                      {option?.agencyName}
                    </MenuItem>
                  ))}
                </FormikController>
              </GridItem>
              <GridItem xs={12}>
                <FormikController
                  control="select"
                  name="locationName"
                  label="Location"
                  disabled={location.length === 0 || locationPageControl?.isEdit === true}
                  required
                  onChange={async (evnt) => {
                    /* set location code value based on the selected locationName using the filterLcnCodeFromLcnName function  */
                    await handleChange(evnt);
                    setFieldValue('locationCode', filterLcnCodeFromLcnName(evnt.target.value));
                  }}
                >
                  {location?.map((option) => (
                    <MenuItem key={option?.locationCode} value={option?.locationName}>
                      {option?.locationName}
                    </MenuItem>
                  ))}
                </FormikController>
              </GridItem>
              <GridItem xs={12}>
                <FormikController control="textField" name="locationCode" label="LocationCode" disabled required />
              </GridItem>
              {locationPageControl?.isEdit === true && (
                <GridItem xs={12}>
                  <FormikController
                    control="switch"
                    name="isDeleted"
                    checked={locationPageControl?.selectedValues?.isDeleted === 'N'}
                    onChange={(e) => {
                      setLocationPageControl((prev) => ({ ...prev, isStatusDialog: true, selectedValues: { ...prev?.selectedValues, isDeleted: e.target.checked === true ? 'N' : 'Y' } }));
                    }}
                    label="Status"
                  />
                </GridItem>
              )}
            </GridContainer>
          </MuiFormDialog>
        )}
      </Formik>
      <Formik initialValues={{ remarks: '' }} onSubmit={handleChangeLocationStatus}>
        {() => (
          <MuiFormDialog open={locationPageControl?.isStatusDialog} dialogTitle={locationStatusDialogTitle} onClose={() => handleCloseLocationStatusDialog()} isNoDirty>
            {locationPageControl?.selectedValues?.isDeleted === 'N' ? (
              <Typography className="text-md font-semibold tracking-tight">Do you want to activate the Queue Management location?</Typography>
            ) : (
              <>
                <Typography className="text-16 font-semibold tracking-tight mb-8">Are you sure, Do you want to deactivate Queue Management Location</Typography>
                <Typography className="text-13 font-semibold tracking-tight">All Tellers & Terminals under this location will be deactivated and processing queue numbers will be removed.</Typography>
              </>
            )}
          </MuiFormDialog>
        )}
      </Formik>
    </Paper>
  );
};
export default LocationPage;
