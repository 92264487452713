import { Autocomplete, TextField } from '@mui/material';
import { ErrorMessage, useFormikContext } from 'formik';
// Mui Autocomplete component
const MuiAutoComplete = (props) => {
  const { name, errors, touched, options, label, getOptionLabel, required, onChange, disabled } = props;

  const { handleBlur } = useFormikContext();
  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      onBlur={handleBlur}
      renderInput={(params) => (
        <TextField
          label={label}
          variant="outlined"
          InputProps={params.InputProps}
          InputLabelProps={params.InputLabelProps}
          inputProps={params.inputProps}
          fullWidth
          autoComplete="off"
          required={required}
          disabled={disabled}
          onBlur={params.onBlur}
          helperText={<ErrorMessage name={name} />}
          error={Boolean(errors[name] && touched[name])}
        />
      )}
    />
  );
};

export default MuiAutoComplete;
