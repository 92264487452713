import { createSlice } from '@reduxjs/toolkit';

const dialogSlice = createSlice({
  name: 'dialog',
  initialState: {
    state: false,
    options: {
      children: '',
    },
    response: {
      error: false,
      title: '',
      message: '',
    },
  },
  reducers: {
    openDialog: (state, action) => {
      const isState = state;
      isState.state = true;
      isState.options = action.payload;
      isState.response = action.payload.response;
      return isState;
    },
    closeDialog: (state) => {
      const isState = state;
      isState.state = false;
      return isState;
    },
  },
});

export const { openDialog, closeDialog } = dialogSlice.actions;

export const selectFuseDialogState = ({ fuse }) => fuse.dialog.state;

export const selectFuseDialogOptions = ({ fuse }) => fuse.dialog.options;

export const selectFuseDialogResponse = ({ fuse }) => fuse.dialog.response;

export default dialogSlice.reducer;
