/* eslint-disable react/jsx-props-no-spreading */
import { TextField } from '@mui/material';
import { Field, ErrorMessage } from 'formik';

/** Input
 * Mui TextField component
 */

const Input = (props) => {
  const { name, errors, touched, fullWidth = true, ...rest } = props;
  return (
    <Field as={TextField} name={name} fullWidth={fullWidth} autoComplete="off" variant="outlined" helperText={<ErrorMessage name={name} />} error={Boolean(errors[name] && touched[name])} {...rest} />
  );
};

export default Input;
