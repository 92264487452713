import { authRoles } from 'src/app/auth';
import ChangePasswordPage from './ChangePassword';

const ChangePasswordConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'change-password',
      element: <ChangePasswordPage />,
    },
  ],
};

export default ChangePasswordConfig;
