const jwtServiceConfig = {
  urmNotification: 'urmNotification',
  masterGetAppConfigs: 'master/getAppConfigs',
  wsSignageNotification: 'wsSignageNotification',
  bobNotification: 'bobNotification',
  test: '/bob/viewFile',
  userView: '/user/view',
  login: 'authentication/login',
  login2FA: 'authentication/login2FA',
  logout: 'authentication/logout',
  refresh: 'authentication/refresh',
  regenerateToken: 'authentication/regenerateToken',
  forgotPassword: 'authentication/forgotPassword',
  resetPassword: 'authentication/resetPassword',
  changePassword: 'authentication/changePassword',
  modifyUserPassword: '/authentication/modifyUserPassword',
  idTypeIdentityTypeDropdown: '/idType/identityTypeDropdown',
  userSearchByName: 'user/searchByName',
  userGetAll: 'user/viewAll',
  userAdd: 'user/add',
  userEdit: 'user/edit',
  userGetForEdit: 'user/view',
  userDelete: 'user/delete',
  userActivateSuspend: 'user/activateOrSuspend',
  userGetUserCodes: '/user/getUserCodes',
  userGetUserCodesRoleWise: '/user/getUserCodesRoleWise',
  userGetUserCodesOrId: '/user/getUserByCodeOrId',
  userGetUserCodeLocationAndAgencyWise: '/user/getUserCodeLocationAndAgencywise',
  userGetUserCodesByPermissionId: '/user/getUserCodesByPermissionId',
  userGetAssociatedRevCodeListForUser: '/user/getAssociatedRevCodeListForUser',
  urmGetAllNotifyConfigurations: 'urm/getAllNotifyConfigurations',
  urmSaveNotificationConfig: 'urm/saveNotificationConfig',
  urmGetAllNotifyConfigPendingRequests: 'urm/getAllNotifyConfigPendingRequests',
  urmApproveNotifyConfigRequest: 'urm/approveNotifyConfigRequest',
  urmRejectNotifyConfigRequest: 'urm/rejectNotifyConfigRequest',
  urmGetNotificationById: 'urm/getNotificationById',
  urmGetAllSettings: 'urm/getAllSettings',
  urmSaveSettings: 'urm/saveSettings',
  masterGetAllPswdCriteriaDetails: 'master/getAllPswdCriteriaDetails',
  masterUpdateInstnDetails: 'master/updateInstnDetails',
  masterGetAllDateTimeFormats: 'master/getAllDateTimeFormats',
  userImportUsers: 'user/importUsers',
  userGetImportUserFile: '/user/getImportUserFile',
  makerCheckerGetAllPendingReqToApprove: 'makerChecker/getAllPendingReqToApprove', // <===== maker checker
  makerCheckerGetAllImportUserPendingRequests: 'makerChecker/getAllImportUserPendingRequests',
  makerCheckerApproveMakerReq: 'makerChecker/approveMakerReq',
  makerCheckerCancelMakerReq: 'makerChecker/cancelMakerReq',
  makerCheckerRejectMakerReq: 'makerChecker/rejectMakerReq',
  makerCheckerGetUserMakerReqDetails: 'makerChecker/getUserMakerReqDetails',
  urmGetAllSettingsPendingRequests: 'urm/getAllSettingsPendingRequests',
  urmApproveSettingsRequest: 'urm/approveSettingsRequest',
  urmRejectSettingsRequest: 'urm/rejectSettingsRequest',
  urmGetSettingsById: 'urm/getSettingsById',
  userGetUserDetailsByLocation: 'user/getUserDetailsByLocation',
  roleGetAll: 'role/viewAll',
  roleViewAllAgencyWiseDropdown: '/role/viewAllAgencyWiseDropdown',
  roleAdd: 'role/addOrEdit',
  roleEdit: 'role/addOrEdit',
  roleGetForEdit: 'role/view',
  roleDelete: 'role/delete',
  roleMap: 'role/mapPermissions',
  roleGetAllRolePendingRequests: 'role/getAllRolePendingRequests',
  roleApproveRoleRequest: 'role/approveRoleRequest',
  roleRejectRoleRequest: 'role/rejectRoleRequest',
  permissionGetAll: 'permission/viewAll',
  permissionViewAllAgencyWise: 'permission/viewAllAgencyWise',
  permissionAdd: 'permission/addOrEdit',
  permissionEdit: 'permission/addOrEdit',
  permissionGetForEdit: 'permission/view',
  permissionDelete: 'permission/delete',
  permissionForRole: 'permission/getPermissionsForRole',
  permissionGetRolePermissions: 'permission/getRolePermissions',
  permissionGetSystemPermissions: 'permission/getSystemPermissions', // System Permission
  permissionMapAgencyPermission: 'permission/mapAgencyPermission', // System Permission
  permissionGetAgencyPermissions: 'permission/getAgencyPermissions', // System Permissio
  permissionConfirmAndUpdateAgencyPermission: '/permission/confirmAndUpdateAgencyPermission',
  locationAdd: 'location/saveLocation',
  locationEdit: 'location/saveLocation',
  locationDeleteLocation: 'location/deleteLocation',
  locationGetLocations: '/location/getLocations',
  locationGetAgencyForQM: 'location/getAgencyForQM',
  serviceAdd: 'service/saveService',
  serviceEdit: 'service/saveService',
  serviceView: 'service/viewservices',
  terminalAdd: 'terminal/saveTerminal',
  terminalEdit: 'terminal/saveTerminal',
  terminalView: 'terminal/viewTerminals',
  terminalStatusChange: 'terminal/changeTerminalStatus',
  queueNumberGeneration: 'queue/generateQueueNumber', // <===== Teller
  queueGetAllQueueForLocation: 'queue/getAllQueueForLocation',
  terminalGetTerminalStatusForLocation: 'terminal/getTerminalStatusForLocation',
  terminalTerminalStatusUpdate: 'terminal/terminalStatusUpdate',
  terminalAssignedQueueForTeller: 'terminal/assignedQueueForTeller',
  queueUpdateQueueByTeller: 'queue/updateQueueByTeller',
  queueRescheduleQueue: 'queue/rescheduleQueue',
  terminalGetQueueDistribution: 'terminal/getQueueDistribution',
  terminalGetActiveTerminalsAssignedQueue: 'terminal/getActiveTerminalsAssignedQueue',
  queueRemoveQueue: 'queue/removeQueue',
  qrGet: 'QR/get',
  qrPost: 'QR/post',
  masterGetAllCountry: 'master/getAllCountry', // <=======Master Management starts here
  masterCreateCountry: 'master/createCountry',
  masterUpdateCountry: 'master/updateCountry',
  masterDeleteCountry: 'master/deleteCountry',
  masterGetCountryDetails: '/master/getCountryDetails',
  masterGetAllCountryPendingRequests: 'master/getAllCountryPendingRequests',
  masterApproveCountryRequest: 'master/approveCountryRequest',
  masterRejectCountryRequest: 'master/rejectCountryRequest',
  masterGetAllState: 'master/getAllState',
  masterGetAllStateByCountryCode: 'master/getAllStateByCountryCode',
  masterCreateState: 'master/createState',
  masterUpdateState: 'master/updateState',
  masterDeleteState: 'master/deleteState',
  masterGetAllStatePendingRequests: 'master/getAllStatePendingRequests',
  masterApproveStateRequest: 'master/approveStateRequest',
  masterRejectStateRequest: 'master/rejectStateRequest',
  masterGetStateById: 'master/getStateById',
  masterGetAllCity: 'master/getAllCity',
  masterGetAllCityByStateCode: 'master/getAllCityByStateCode',
  masterCreateCity: 'master/createCity',
  masterUpdateCity: 'master/updateCity',
  masterDeleteCity: 'master/deleteCity',
  masterGetAllCityPendingRequests: 'master/getAllCityPendingRequests',
  masterApproveCityRequest: 'master/approveCityRequest',
  masterRejectCityRequest: 'master/rejectCityRequest',
  masterGetCityById: 'master/getCityById',
  masterGetLocationCodes: 'master/getLocationCodes',
  masterAddLocation: '/master/addLocation',
  masterEditLocation: 'master/editLocation',
  masterDeleteLocation: 'master/deleteLocation',
  masterGetAllLocation: 'master/getAllLocation',
  masterGetAllLocationPendingRequests: 'master/getAllLocationPendingRequests',
  masterApproveLocationRequest: 'master/approveLocationRequest',
  masterRejectLocationRequest: 'master/rejectLocationRequest',
  masterGetLocation: 'master/getLocation',
  masterGetAllLocationCodesAgencywise: '/master/getAllLocationCodesAgencywise',
  masterGetAllPaymentCategory: 'master/getAllPaymentCategory',
  masterSavePaymentCategory: 'master/savePaymentCategory',
  masterGetChildrenCategory: 'master/getChildrenCategory',
  masterGetPaymentCategoryDetails: '/master/getPaymentCategoryDetails',
  masterUpdatePymntCategoryStatus: 'master/updatePymntCategoryStatus',
  masterGetAllPymntCategoryPendingRequests: 'master/getAllPymntCategoryPendingRequests',
  masterApprovePymntCategoryRequest: 'master/approvePymntCategoryRequest',
  masterRejectPymntCategoryRequest: 'master/rejectPymntCategoryRequest',
  getLocationCodes: '/master/getLocationCodes',
  masterCBSAccountDropdown: '/master/cbsAccountDropdown',
  masterGetAllNotifyConfigurations: 'master/getAllNotifyConfigurations',
  masterSaveNotificationConfig: 'master/saveNotificationConfig',
  masterGetAllNotifyConfigPendingRequests: 'master/getAllNotifyConfigPendingRequests',
  masterApproveNotifyConfigRequest: 'master/approveNotifyConfigRequest',
  masterRejectNotifyConfigRequest: 'master/rejectNotifyConfigRequest',
  masterGetNotificationById: 'master/getNotificationById',
  oagdoCustomerPayment: '/oag/doCustomerPayment', // <======= OAG starts here
  oagGetStagingDetails: '/oag/getStagingDetails',
  oagGetUrnHistory: '/oag/getUrnHistory',
  oagUrnSearch: '/oag/urnSearch',
  oagSearchS3: '/oag/searchS3',
  oagSearchS3FutureResult: '/oag/searchS3FutureResult',
  oagSearchS3History: '/oag/searchS3History',
  bobGetStagingDetailWithHistory: '/bob/getStagingDetailWithHistory',
  oagDoStaging: '/oag/doStaging',
  oagdoCommBankDeposit: '/oag/doCommBankDeposit',
  oagGetListOfPaymentsForStaging: '/oag/getListOfPaymentsForStaging',
  oagGetStagedTransaction: '/oag/getStagedTransaction',
  oagGetAllTransaction: '/oag/getAllTransaction',
  oagGetUserTransactions: '/oag/getUserTransactions',
  oagGetListOfPaymentForComBankTxn: '/oag/getListOfPaymentForComBankTxn',
  oagDoComBankTStaging: '/oag/doComBankTStaging',
  oagAssignConsolidation: '/oag/assignConsolidation',
  oagDoCustomerPaymentList: 'oag/doCustomerPaymentList',
  oagDoCollectStagedAmountList: 'oag/doCollectStagedAmountList',
  oagGetAllNotifyConfigurations: 'oag/getAllNotifyConfigurations',
  oagSaveNotificationConfig: 'oag/saveNotificationConfig',
  oagGetAllNotifyConfigPendingRequests: 'oag/getAllNotifyConfigPendingRequests',
  oagApproveNotifyConfigRequest: 'oag/approveNotifyConfigRequest',
  oagRejectNotifyConfigRequest: 'oag/rejectNotifyConfigRequest',
  oagGetNotificationById: 'oag/getNotificationById',
  oagGetAllPaymentCategory: '/oag/getAllPaymentCategory', // <===== oag payment category
  oagCreatePaymentCategory: '/oag/createPaymentCategory',
  oagUpdatePaymentCategory: '/oag/updatePaymentCategory',
  oagDeletePaymentCategory: '/oag/deletePaymentCategory',
  oagGetAllPrimaryCategory: '/oag/getAllPrimaryCategory',
  oagGetPaymentCategory: '/oag/getPaymentCategory',
  bobGetVerficationPendingRequests: '/bob/getVerificationPendingRequests', // <===== Bob Starts here
  bobGetAuthorizePendingRequests: '/bob/getAuthorizePendingRequests',
  bobRaisePublicOtherPaymentRequest: 'bob/raisePublicOtherPaymentRequest',
  bobVerifyPublicOtherPayment: '/bob/verifyPublicOtherPayment',
  bobAuthorizePublicOtherPayment: '/bob/authorizePublicOtherPayment',
  bobRaiseExchangeMutilatedNotesRequest: 'bob/raiseExchangeMutilatedNotesRequest',
  bobViewExchangeMutilatedNotesRequestStatus: 'bob/viewExchangeMutilatedNotesRequestStatus',
  bobGetAllDenominationConfig: 'bob/getAllDenominationConfig',
  bobRaiseAbandonedFundRequest: '/bob/raiseAbandonedFundRequest',
  bobGetRaisedAbandonedFundRequests: '/bob/getRaisedAbandonedFundRequests',
  bobVerifyAbandonedFundRequest: '/bob/verifyAbandonedFundRequest',
  bobGetVerifiedAbandonedFundRequests: '/bob/getVerifiedAbandonedFundRequests',
  bobAuthorizeAbandonedFundRequest: '/bob/authorizeAbandonedFundRequest',
  bobGetAbandonedFundEftRequests: '/bob/getAbandonedFundEftRequests',
  bobGetLockedAbandonedFundEFTRequest: '/bob/getLockedAbandonedFundEFTRequest',
  bobAbandonedFundFailEFTRequest: '/bob/abandonedFundFailEFTRequest',
  bobAbandonedFundCompleteEFTRequest: '/bob/abandonedFundCompleteEFTRequest',
  bobAbandonedFundEFTRequestAction: '/bob/abandonedFundEFTRequestAction',
  bobViewMomFile: '/bob/viewMomFile',
  bobCompleteAbandonedFundEftRequest: '/bob/completeAbandonedFundEftRequest',
  bobViewAbandonedFundRequestStatus: '/bob/viewAbandonedFundRequestStatus',
  bobViewAbandonedfundEFTRequest: '/bob/viewAbandonedfundEFTRequest',
  bobRaiseEFTRequest: '/bob/raiseEFTRequest', // <===== EFT Starts here
  bobGetEFTRequests: '/bob/getEFTRequests',
  bobEFTRequestAction: '/bob/EFTRequestAction',
  bobGetLockedEFTRequest: '/bob/getLockedEFTRequest',
  bobGetSearchLockedEFTRequests: '/bob/getSearchLockedEFTRequest',
  bobFailEFTRequest: '/bob/failEFTRequest',
  bobCompleteEFTRequest: '/bob/completeEFTRequest',
  bobViewEFTRequest: '/bob/viewEFTRequest',
  bobGetFailedAndRejectedEFTRequests: '/bob/getFailedAndRejectedEFTRequests',
  bobRaiseEFTReturnRequest: '/bob/raiseEftReturnRequest',
  bobViewEFTReturn: '/bob/viewEFTReturn',
  bobGetPendingEFTReturnRequests: '/bob/getPendingEFTReturnRequests',
  bobVerifyEFTReturnRequest: '/bob/verifyEFTReturnRequest',
  bobGetPendingVerifiedEFTReturnRequests: '/bob/getPendingVerifiedEFTReturnRequests',
  bobAuthorizeVerifiedEFTReturn: '/bob/authorizeVerifiedEFTReturnRequest',
  bobGetApprovedEFTReturnRequestNotWithdrawal: '/bob/getApprovedEFTReturnRequestNotWithdrawal',
  bobApproverCancelTransactionEFTReturnRequest: '/bob/approverCancelTransactionEFTReturnRequest',
  bobRaiseForeignExchangeRequest: '/bob/raiseForeignExchangeRequest', // <===== FE Starts here
  bobRaiseForeignExchangeEftRequest: 'bob/raiseForeignExchangeEftRequest',
  bobGetPendingForeignExchangeRequests: '/bob/getPendingForeignExchangeRequests',
  bobVerifyForeignExchangeRequest: '/bob/verifyForeignExchangeRequest',
  bobGetPendingVerifiedForeignExchangeRequests: '/bob/getPendingVerifiedForeignExchangeRequests',
  bobAuthorizeVerifiedForeignExchangeRequest: '/bob/authorizeVerifiedForeignExchangeRequest',
  bobViewForeignExchangeRequest: '/bob/viewForeignExchangeRequest',
  bobGetForeignExchangeRequests: '/bob/getForeignExchangeRequests',
  bobSubmitEFTReceipt: '/bob/submitEFTReceipt',
  bobGetForeignExchangeEftRequests: 'bob/getForeignExchangeEftRequests',
  bobForeignExchangeEFTRequestAction: 'bob/foreignExchangeEFTRequestAction',
  bobGetLockedForeignExchangeEFTRequest: 'bob/getLockedForeignExchangeEFTRequest',
  bobFailForeignExchangeEftRequest: 'bob/failForeignExchangeEftRequest',
  bobCompleteForeignExchangeEftRequest: 'bob/completeForeignExchangeEftRequest',
  bobViewForeignExchangeEFTRequest: 'bob/viewForeignExchangeEFTRequest',
  bobRaisePettyCashWithdrawalRequest: '/bob/raisePettyCashWithdrawalRequest',
  bobGetRaisedPettyCashWithdrawalRequests: '/bob/getRaisedPettyCashWithdrawalRequests',
  bobVerifyPettyCashWithdrawalRequest: '/bob/verifyPettyCashWithdrawalRequest',
  bobForeignExchangeRequestAction: '/bob/foreignExchangeRequestAction',
  bobGetLockedForeignExchangeRequest: '/bob/getLockedForeignExchangeRequest',
  bobCompleteForeignExchangeRequest: '/bob/completeForeignExchangeRequest',
  bobGetSearchCompleteForeignExchangeRequest: '/bob/getSearchCompleteForeignExchangeRequest',
  bobFailForeignExchangeRequest: '/bob/failForeignExchangeRequest',
  bobRaiseForeignExchangeReturnRequest: '/bob/raiseForeignExchangeReturnRequest',
  bobGetPendingForeignExchangeReturnRequests: '/bob/getPendingForeignExchangeReturnRequests',
  bobVerifyForeignExchangeReturnRequest: '/bob/verifyForeignExchangeReturnRequest',
  bobGetPendingVerifiedForeignExchangeReturnRequests: '/bob/getPendingVerifiedForeignExchangeReturnRequests',
  bobAuthorizeVerifiedForeignExchangeReturnRequest: '/bob/authorizeVerifiedForeignExchangeReturnRequest',
  bobViewForeignExchangeReturnRequest: '/bob/viewForeignExchangeReturnRequest',
  bobGetApprovedFEReturnRequestNotWithdrawal: '/bob/getApprovedFEReturnRequestNotWithdrawal',
  bobApproverCancelTransactionFEReturnRequest: '/bob/approverCancelTransactionFEReturnRequest',
  bobViewEFTReceiptFile: '/bob/viewEFTReceiptFile',
  bobGetVerifiedPettyCashWithdrawalRequests: '/bob/getVerifiedPettyCashWithdrawalRequests', // <=====Petty cash
  bobAuthorizePettyCashWithdrawalRequest: '/bob/authorizePettyCashWithdrawalRequest',
  bobGetAuthorizedAndRejectedPettyCashWithdrawalRequests: '/bob/getAuthorizedAndRejectedPettyCashWithdrawalRequests',
  bobRaisePettyCashChangeRequest: '/bob/raisePettyCashChangeRequest',
  bobGetRaisedPettyCashChangeRequests: '/bob/getRaisedPettyCashChangeRequests',
  bobVerifyPettyCashChangeRequest: '/bob/verifyPettyCashChangeRequest',
  bobGetAllPettyCashFileDetails: '/bob/getAllPettyCashFileDetails',
  bobGetVerifiedPettyCashChangeRequests: '/bob/getVerifiedPettyCashChangeRequests',
  bobAuthorizePettyCashChangeRequest: '/bob/authorizePettyCashChangeRequest',
  bobViewPettyCashWithdrawalRequestStatus: '/bob/viewPettyCashWithdrawalRequestStatus',
  bobViewPettyCashChangeRequestStatus: '/bob/viewPettyCashChangeRequestStatus',
  bobGetAuthorizedAndRejectedPettyCashChangeRequests: '/bob/getAuthorizedAndRejectedPettyCashChangeRequests',
  bobGetAllMyPettyCashWithdrawalRequest: '/bob/getAllMyPettyCashWithdrawalRequest',
  bobGetAllMyPettyCashChangeRequest: '/bob/getAllMyPettyCashChangeRequest',
  bobRaiseStaffOtherPaymentRequest: '/bob/raiseStaffOtherPaymentRequest',
  bobViewStaffOtherRequestStatus: '/bob/viewStaffOtherRequestStatus',
  bobGetRejectedPettyCashChangeRequests: '/bob/getRejectedPettyCashChangeRequests',
  bobCompletePettyCashRequest: 'bob/completePettyCashRequest',
  bobViewFile: '/bob/viewFile',
  bobViewFileBase64: '/bob/viewFileBase64',
  bobgetCommercialBankUserTransactions: '/bob/getCommercialBankUserTransactions', // <===== Bob commercial bank Starts here
  bobGetUrnTransactions: '/bob/getUrnTransactions', // <==== dashboard starts here
  bobGetBundleTransactions: '/bob/getBundleTransactions',
  bobGetCollectionTransactions: '/bob/getCollectionTransactions',
  bobGetConsoliadtionTransactions: '/bob/getConsoliadtionTransactions',
  bobGetBankingHallDepositTransactions: '/bob/getBankingHallDepositTransactions',
  bobGetTodayTransactionAmount: '/bob/getTodayTransactionAmount',
  rpGetGovtDashboardRevenueCollection: 'rp/getGovtDashboardRevenueCollection',
  bobGetDashboardCashMovement: '/bob/getDashboardCashMovement',
  bobGetAllCommercialBankCode: '/bob/getAllCommercialBankCode', // <=================== Commercial Bank starts here
  bobGetParentCommercialBankCode: 'bob/getParentCommercialBankCode',
  bobGetCommercialBank: 'bob/getCommercialBank',
  bobGetAllCommercialBank: '/bob/getAllCommercialBank',
  bobGetBankBranches: '/bob/getBankBranches',
  bobAddCommercialBank: 'bob/addCommercialBank',
  bobEditCommercialBank: '/bob/editCommercialBank',
  bobDeleteCommercialBank: '/bob/deleteCommercialBank',
  bobGetAllComBankPendingRequests: 'bob/getAllComBankPendingRequests',
  bobApproveComBankRequest: 'bob/approveComBankRequest',
  bobRejectComBankRequest: 'bob/rejectComBankRequest',
  bobGetAllAgencyByBankId: '/bob/getAllAgencyByBankId',
  bobAuditLogForReport: '/bob/auditLogForReport',
  rpGetAuditLogs: '/rp/getAuditLogs',
  rpGetUrnAndS3SearchAuditHistory: '/rp/getUrnAndS3SearchAuditHistory', // <=========  reports
  rpGetCustPymntReport: '/rp/getCustPymntReport',
  rpGetBobAdminReports: '/rp/getBobAdminReports',
  rpGetBursAdminReports: '/rp/getBursAdminReports',
  rpGetBursConsolidationsReport: '/rp/GetBursConsolidationsReport',
  rpGetBursCashierReports: '/rp/getBursCashierReports',
  rpGetOagConsolidationsReport: '/rp/GetOagConsolidationsReport',
  rpGetOagBundleCashReports: '/rp/getOagBundleCashReports',
  rpGetOagCustomerPaymentReport: '/rp/getOagCustomerPaymentReport', // <==============Reports
  rpGetBundleCashReport: '/rp/getBundleCashReport',
  rpGetBundleCashAcceptenceRpt: '/rp/getBundleCashAcceptenceRpt',
  rpGetAssignedConsolidationRpt: '/rp/getAssignedConsolidationRpt',
  rpGetConsolidationReport: '/rp/getConsolidationReport',
  rpGetDailyReport: '/rp/getDailyReport',
  rpGetForeignExchangeReport: '/rp/getForeignExchangeRpt',
  rpGetEFTReport: '/rp/getEftReport',
  rpGetMutilatedNotesExchangeReport: '/rp/getMutilatedNotesExchgeRpt',
  rpGetAbandonedFundRequest: '/rp/getAbandonedFundRequest',
  rpGetPartialDepositRpt: '/rp/getPartialDepositRpt',
  rpGetQmCrmReport: '/rp/getQmCrmReport',
  rpGetQmCoinsReport: '/rp/getQmCoinsReport',
  rpgetCommBankDepositReport: '/rp/getCommBankDepositRpt',
  rpGetCommBankFundTransferRpt: '/rp/getCommBankFundTransferRpt',
  rpGetPettyCashReport: 'rp/getPettyCashReport',
  rpGetOtherStaffPaymentReport: 'rp/getOtherStaffPaymentReport',
  bobAddOrEditAgency: 'bob/addOrEditAgency', // <=======================agency code starts here
  bobAddAgency: 'bob/addAgency',
  bobEditAgency: 'bob/editAgency',
  bobGetAllAgencyCode: 'bob/getAllAgencyCode',
  bobGetAgency: 'bob/getAgency',
  bobGetAllAgency: '/bob/getAllAgency',
  bobGetSubAgency: '/bob/getSubAgency',
  bobGetSubAgencyCode: 'bob/getSubAgencyCode',
  bobGetAllAgencyPendingRequests: 'bob/getAllAgencyPendingRequests',
  bobApproveAgencyRequest: 'bob/approveAgencyRequest',
  bobRejectAgencyRequest: 'bob/rejectAgencyRequest',
  bobGetLinkedBankId: '/bob/getLinkedBankId',
  bobSearchS3: '/bob/searchS3',
  bobUrnSearch: '/bob/urnSearch',
  bobGetStagingDetailsWithHistory: '/bob/getStagingDetailWithHistory',
  dashboardGetUserCountByStatus: '/dashboard/getUserCountByStatus',
  dashboardGetUserDetailsByStatus: '/dashboard/getUserDetailsByStatus',
  dashboardGetUserCountByLastActiveTime: '/dashboard/getUserCountByLastActiveTime',
  dashboardGetUserDetailsByLastActiveTime: '/dashboard/getUserDetailsByLastActiveTime',
  dashboardGetUserCountAgencywise: '/dashboard/getUserCountAgencywise',
  dashboardGetAdminDashboardUserDetail: 'dashboard/getAdminDashboardUserDetail',
  oagGetDashboardBobDistOverview: '/oag/getDashboardBobDistOverview',
  oagGetDshBoardRevenueCollDetailedView: '/oag/getDshBoardRevenueCollDetailedView',
  rpGetDashboardRevenueCollection: '/rp/getDashboardRevenueCollection',
  limitsGetDashboardData: '/limits/getDashboardData',
  limitsGetStagingDue: '/limits/getStagingDue',
  limitsGetDshBrdViolationofBdleNotDeposit: '/limits/getDshBrdViolationOfBdleNotDeposit',
  // bobDeleteAgency: "bob/deleteAgency",
  brDoStaging: 'br/doStaging', // <===== burs payment category
  brGetListOfPaymentsForStaging: 'br/getListOfPaymentsForStaging',
  brDoCommBankDeposit: 'br/doCommBankDeposit',
  brGetStagedTransaction: 'br/getStagedTransaction',
  brGetListOfPaymentForComBankTxn: 'br/getListOfPaymentForComBankTxn',
  brDoComBankTStaging: 'br/doComBankTStaging',
  brGetUserTranscations: '/br/getUserTransactions',
  burGetAllNotifyConfigurations: 'bur/getAllNotifyConfigurations',
  burSaveNotificationConfig: 'bur/saveNotificationConfig',
  burGetAllNotifyConfigPendingRequests: 'bur/getAllNotifyConfigPendingRequests',
  burApproveNotifyConfigRequest: 'bur/approveNotifyConfigRequest',
  burRejectNotifyConfigRequest: 'bur/rejectNotifyConfigRequest',
  burGetNotificationById: 'bur/getNotificationById',
  masterGetAllCbsAccount: '/master/getAllCbsAccount',
  masterUpdateCbsAccount: '/master/updateCbsAccount',
  masterCreateCbsAccount: '/master/createCbsAccount',
  masterGetAllCbsAccountPendingRequests: 'master/getAllCbsAccountPendingRequests',
  masterApproveCbsAccountRequest: 'master/approveCbsAccountRequest',
  masterRejectCbsAccountRequest: 'master/rejectCbsAccountRequest',
  masterGetCbsAccount: 'master/getCbsAccount',
  masterGetAllCbsAccountAgencyWise: 'master/getAllCbsAccountAgencyWise',
  masterGetDistinctCbsAccountNames: 'master/getDistinctCbsAccountNames',
  masterGetCbsAccountByPaymentCategoryCode: 'master/getCbsAccountByPaymentCategoryCode',
  masterCreateChannel: '/master/createChannel',
  masterUpdateChannel: '/master/updateChannel',
  masterGetAllChannel: '/master/getAllChannel',
  masterGetAllChannelDropdown: '/master/getAllChannelDropdown',
  masterGetAllChannelPendingRequests: 'master/getAllChannelPendingRequests',
  masterApproveChannelRequest: 'master/approveChannelRequest',
  masterRejectChannelRequest: 'master/rejectChannelRequest',
  masterGetChannel: 'master/getChannel',
  masterGetAllConnectivity: '/master/getAllConnectivity',
  masterCreateConnectivity: '/master/createConnectivity',
  masterUpdateConnectivity: '/master/updateConnectivity',
  masterGetAllConnectivityDropdown: '/master/getAllConnectivityDropdown',
  masterGetAllConnectivityPendingRequests: 'master/getAllConnectivityPendingRequests',
  masterApproveConnectivityRequest: 'master/approveConnectivityRequest',
  masterRejectConnectivityRequest: 'master/rejectConnectivityRequest',
  masterGetConnectivity: 'master/getConnectivity',
  masterSaveTransactionType: '/master/saveTransactionType',
  masterGetAllTransactionTypes: '/master/getAllTransactionTypes',
  masterGetAllTxnTypePendingRequests: 'master/getAllTxnTypePendingRequests',
  masterApproveTransactionTypeRequest: 'master/approveTransactionTypeRequest',
  masterRejectTransactionTypeRequest: 'master/rejectTransactionTypeRequest',
  masterGetTransactionTypeById: 'master/getTransactionTypeById',
  masterGetAllRevenueCode: '/master/getAllRevenueCode',
  masterCreateRevenueCode: '/master/createRevenueCode',
  masterUpdateRevenueCode: '/master/updateRevenueCode',
  masterGetAllRevenueCodeGroup: '/master/getAllRevenueCodeGroup',
  masterCreateRevenueCodeGroup: '/master/createRevenueCodeGroup',
  masterUpdateRevenueCodeGroup: '/master/updateRevenueCodeGroup',
  masterGetRevenueCodeGroup: '/master/getRevenueCodeGroup',
  masterMapRevenueCodes: '/master/mapRevenueCodes',
  masterGetAllRevenueCodeAgencyWise: '/master/getAllRevenueCodeAgencyWise',
  masterGetAllRevenueCodeByRevenueCodeGroupId: '/master/getAllRevenueCodeByRevenueCodeGroupId',
  masterGetAllRevenueCodeGrpPendingRequests: 'master/getAllRevenueCodeGrpPendingRequests',
  masterApproveRevenueCodeGrpRequest: 'master/approveRevenueCodeGrpRequest',
  masterRejectRevenueCodeGrpRequest: 'master/rejectRevenueCodeGrpRequest',
  masterRevenueCodeGroupDropdown: '/master/revenueCodeGroupDropdown',
  masterGetAllRevenueCodeGroupByRevenueCode: '/master/getAllRevenueCodeGroupByRevenueCode',
  masterGetAllRevenueCodePendingRequests: 'master/getAllRevenueCodePendingRequests',
  masterApproveRevenueCodeRequest: 'master/approveRevenueCodeRequest',
  masterRejectRevenueCodeRequest: 'master/rejectRevenueCodeRequest',
  masterGetRevenueCode: 'master/getRevenueCode',
  bobValidateStagingReference: '/bob/validateStagingReference',
  bobCancelWithdrawalReference: '/bob/cancelWithdrawalReference',
  bobAcceptTransaction: '/bob/acceptTransaction',
  bobGetWithdrawalReference: '/bob/getWithdrawalReference',
  LimitsGetUserLimitDetails: '/limits/getUserLimitDetails',
  LimitsMakerCheckerSaveMakerReq: '/limits/makerChecker/saveMakerReq',
  UserGetUsersForRoleAndLimit: '/user/getUsersForRoleAndLimit',
  LimitsMakerCheckerGetAllPendingReqToApprove: '/limits/makerChecker/getAllPendingReqToApprove',
  LimitsMakerCheckerApproveMakerReq: '/limits/makerChecker/approveMakerReq',
  LimitsMakerCheckerRejectMakerReq: '/limits/makerChecker/rejectMakerReq',
  notificationGetUserNotifications: '/notification/getUserNotifications',
  notificationDeleteUserNotifications: '/notification/deleteUserNotification',
  profileSave: '/profile/save',
  profileRemove: '/profile/remove',
  profileGet: '/profile/get',
  fpGetAllUploadedFileDetails: '/fp/getAllUploadedFileDetails',
  fpGetAllUploadedFileDetailsAgencyWise: '/fp/getAllUploadedFileDetailsAgencyWise',
  fpUploadFile: '/fp/uploadFile',
  fpViewFileBase64: '/fp/viewFileBase64',
  fpUploadMultipleFile: '/fp/uploadMultipleFile',
  fpGetAllJobDetails: '/fp/getAllJobDetails',
};

export { jwtServiceConfig };
