import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const ProcessEFTApplications = lazy(() => import('./bank-employee-services-management/process-eft-applications/ProcessEFTApplications'));
const ProcessEFTReturns = lazy(() => import('./bank-employee-services-management/process-eft-returns/ProcessEFTReturns'));
const ReviewEFTReturns = lazy(() => import('./bank-employee-services-management/review-eft-returns/ReviewEFTReturns'));
const ApproveEFTReturns = lazy(() => import('./bank-employee-services-management/approve-eft-returns/ApproveEFTReturns'));
const ReviewForeignExchangeRequests = lazy(() => import('./bank-employee-services-management/review-foreign-exchange-requests/ReviewForeignExchangeRequests'));
const ApproveForeignExchangeRequests = lazy(() => import('./bank-employee-services-management/approve-foreign-exchange-requests/ApproveForeignExchangeRequests'));
const ProcessForeignExchangeApplications = lazy(() => import('./bank-employee-services-management/process-foreign-exchange-applications/ProcessForeignExchangeApplications'));
const ProcessForeignExchangeReturns = lazy(() => import('./bank-employee-services-management/process-foreign-exchange-returns/ProcessForeignExchangeReturns'));
const ReviewForeignExchangeReturns = lazy(() => import('./bank-employee-services-management/review-foreign-exchange-returns/ReviewForeignExchangeReturns'));
const ApproveForeignExchangeReturns = lazy(() => import('./bank-employee-services-management/approve-foreign-exchange-returns/ApproveForeignExchangeReturns'));
const PettyCashRequestApplication = lazy(() => import('./bank-employee-services-management/petty-cash-request-application/PettyCashRequestApplication'));
const ViewPettyCashRequests = lazy(() => import('./bank-employee-services-management/view-petty-cash-requests/ViewPettyCashRequests'));
const ReviewPettyCashRequests = lazy(() => import('./bank-employee-services-management/review-petty-cash-requests/ReviewPettyCashRequests'));
const ApprovePettyCashRequests = lazy(() => import('./bank-employee-services-management/approve-petty-cash-requests/ApprovePettyCashRequests'));
const ReviewPettyCashReturns = lazy(() => import('./bank-employee-services-management/review-petty-cash-returns/ReviewPettyCashReturns'));
const ApprovePettyCashReturns = lazy(() => import('./bank-employee-services-management/approve-petty-cash-returns/ApprovePettyCashReturns'));
const ReceiptForBOB = lazy(() => import('./ReceiptsForBOB'));

const BankEmployeeServiceManagementConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'module/bank-employee-service-management',
      children: [
        {
          path: '',
          element: <Navigate to="process-eft-applications" />,
        },
        {
          path: 'process-eft-applications',
          element: <ProcessEFTApplications />,
        },
        {
          path: 'process-eft-returns',
          element: <ProcessEFTReturns />,
        },
        {
          path: 'review-eft-returns',
          element: <ReviewEFTReturns />,
        },

        {
          path: 'approve-eft-returns',
          element: <ApproveEFTReturns />,
        },
        {
          path: 'review-foreign-exchange-requests',
          element: <ReviewForeignExchangeRequests />,
        },
        {
          path: 'approve-foreign-exchange-requests',
          element: <ApproveForeignExchangeRequests />,
        },
        {
          path: 'process-foreign-exchange-applications',
          element: <ProcessForeignExchangeApplications />,
        },
        {
          path: 'process-foreign-exchange-returns',
          element: <ProcessForeignExchangeReturns />,
        },
        {
          path: 'review-foreign-exchange-returns',
          element: <ReviewForeignExchangeReturns />,
        },
        {
          path: 'approve-foreign-exchange-returns',
          element: <ApproveForeignExchangeReturns />,
        },
        {
          path: 'petty-cash-request-application',
          element: <PettyCashRequestApplication />,
        },
        {
          path: 'view-petty-cash-requests',
          element: <ViewPettyCashRequests />,
        },
        {
          path: 'review-petty-cash-requests',
          element: <ReviewPettyCashRequests />,
        },
        {
          path: 'approve-petty-cash-requests',
          element: <ApprovePettyCashRequests />,
        },
        {
          path: 'review-petty-cash-returns',
          element: <ReviewPettyCashReturns />,
        },

        {
          path: 'approve-petty-cash-returns',
          element: <ApprovePettyCashReturns />,
        },
        {
          path: 'txn-receipt-success',
          element: <ReceiptForBOB />,
        },
      ],
    },
  ],
};

export default BankEmployeeServiceManagementConfig;
