import { Button, Grid, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik, getIn } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
//
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
//
import { useDispatch } from 'react-redux';
import { openDialog } from 'app/store/fuse/dialogSlice';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { logoutUser } from 'app/store/userSlice';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getPwdConfig, getValidationSchemaChangePassword } from '../helperFunctionPasswordModule';

function ChangePasswordPage() {
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    password: false,
    newPassword: false,
  });
  const [pwdConfig, setPwdConfig] = useState(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  useEffect(() => {
    if (!state) {
      dispatch(logoutUser());
      navigate('/sign-in');
    }
  }, [state]);

  useEffect(() => {
    const getPwdLength = getPwdConfig();
    if (getPwdLength) {
      setPwdConfig(Number(getPwdLength));
    } else {
      setPwdConfig(6);
    }
  }, []);

  const validationSchema = getValidationSchemaChangePassword(pwdConfig);

  const handleSubmit = async (values, props) => {
    const data = {
      loginUserName: state.loginUsername,
      oldPassword: values.oldPassword,
      newPassword: values.newPassword,
    };
    console.log(data);

    const res = await axios.post(jwtServiceConfig.modifyUserPassword, data);
    console.log(res);
    if (res.data.responseCode === 0) {
      dispatch(
        openDialog({
          response: {
            title: res.data.message,
            message: res.data.data.message,
          },
        })
      );
      setTimeout(() => {
        dispatch(logoutUser());
        navigate('/sign-in');
      }, 2000);
    } else {
      dispatch(
        openDialog({
          response: {
            error: true,
            title: res.data.message,
            message: res.data.errors[0].message,
          },
        })
      );
    }

    props.resetForm();
  };

  const handlePreventCopyPaste = (e) => {
    e.preventDefault();
  };

  return (
    <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
      <Paper className="w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
        <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
          <Typography className="mt-32 text-4xl font-extrabold tracking-tight leading-tight">Change password</Typography>
          {/* <div className='flex items-baseline mt-2 font-medium'>
              <Typography>Fill the form to reset your password</Typography>
            </div> */}
          <Formik
            initialValues={{
              oldPassword: '',
              newPassword: '',
              confirmPassword: '',
            }}
            onSubmit={handleSubmit}
            enableReinitialize
            validationSchema={validationSchema}
            validateOnBlur={false}
          >
            {({ errors, touched }) => (
              <Form className="flex flex-col justify-center w-full mt-32" noValidate>
                <Grid>
                  <Field
                    as={TextField}
                    name="oldPassword"
                    label="Old Password"
                    type={showPassword.oldPassword ? 'text' : 'password'}
                    fullWidth
                    required
                    onCut={handlePreventCopyPaste}
                    onCopy={handlePreventCopyPaste}
                    onPaste={handlePreventCopyPaste}
                    className="mb-24"
                    variant="outlined"
                    helperText={<ErrorMessage name="oldPassword" />}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPassword((prev) => ({
                                ...prev,
                                oldPassword: !prev.oldPassword,
                              }))
                            }
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword.oldPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(getIn(errors, 'oldPassword') && getIn(touched, 'oldPassword'))}
                  />
                </Grid>
                <Grid>
                  <Field
                    as={TextField}
                    name="newPassword"
                    label="New Password"
                    fullWidth
                    required
                    onCut={handlePreventCopyPaste}
                    onCopy={handlePreventCopyPaste}
                    onPaste={handlePreventCopyPaste}
                    type={showPassword.password ? 'text' : 'password'}
                    className="mb-24"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPassword((prev) => ({
                                ...prev,
                                password: !prev.password,
                              }))
                            }
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword.password ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{ maxLength: 10 }}
                    helperText={<ErrorMessage name="newPassword" />}
                    error={Boolean(getIn(errors, 'newPassword') && getIn(touched, 'newPassword'))}
                  />
                </Grid>
                <Grid>
                  <Field
                    as={TextField}
                    name="confirmPassword"
                    label="Confirm Password"
                    fullWidth
                    required
                    onCut={handlePreventCopyPaste}
                    onCopy={handlePreventCopyPaste}
                    onPaste={handlePreventCopyPaste}
                    type={showPassword.newPassword ? 'text' : 'password'}
                    className="mb-24"
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPassword((prev) => ({
                                ...prev,
                                newPassword: !prev.newPassword,
                              }))
                            }
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={<ErrorMessage name="confirmPassword" />}
                    error={Boolean(getIn(errors, 'confirmPassword') && getIn(touched, 'confirmPassword'))}
                  />
                </Grid>
                <Button variant="contained" color="secondary" className=" w-full mt-4" aria-label="Register" type="submit" size="large">
                  Change Password
                </Button>
                <Typography className="mt-32 text-md font-medium" color="text.secondary">
                  <span>Return to</span>
                  <Link className="ml-4" to="/sign-in">
                    sign in
                  </Link>
                </Typography>
              </Form>
            )}
          </Formik>
        </div>
      </Paper>
    </div>
  );
}

export default ChangePasswordPage;
