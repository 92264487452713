import { Navigate } from 'react-router-dom';
import Location from './location/Location';
import Services from './service/Service';
import Terminal from './terminal/Terminal';
import BankingHallStatus from './banking-hall-status/BankingHallStatus';
import AssignedQueue from './assigned-queue/AssignedQueue';
import QueueHistory from './queue-history/QueueHistory';
import TerminalStatus from './terminal-status/TerminalStatus';

const QueueManagementConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'module/queue-management',
      children: [
        {
          path: '',
          element: <Navigate to="location" />,
        },
        {
          path: 'location',
          element: <Location />,
        },
        {
          path: 'services',
          element: <Services />,
        },
        {
          path: 'terminal',
          element: <Terminal />,
        },
        {
          path: 'banking-hall-status',
          element: <BankingHallStatus />,
        },
        {
          path: 'assigned-queue',
          element: <AssignedQueue />,
        },
        {
          path: 'queue-history',
          element: <QueueHistory />,
        },
        {
          path: 'terminal-status',
          element: <TerminalStatus />,
        },
      ],
    },
  ],
};

export default QueueManagementConfig;
