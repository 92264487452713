import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
const reactPlugin = new ReactPlugin();
const connectionString = process.env.REACT_APP_APPINSIGHTS_CONNECTION_STRING;
const appInsights = new ApplicationInsights({
    config: {
        connectionString,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: null },
        },
    },
});
appInsights.loadAppInsights();

export { appInsights, reactPlugin };