import FuseNavigation from '@fuse/core/FuseNavigation';
import clsx from 'clsx';
import { memo, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectNavigation, setNavigation } from 'app/store/fuse/navigationSlice';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import { selectUser } from 'app/store/userSlice';

function Navigation(props) {
  const navigation = useSelector(selectNavigation);
  const user = useSelector(selectUser);
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      if (user?.rolePermissions !== null && user?.rolePermissions?.length > 0) {
        navigation.forEach((navFirst, navFirstIndex) => {
          if (navFirst?.children) {
            const parentIndexArr = [];
            navFirst.children.forEach((navSecond, navSecondIndex) => {
              const indexArr = [];
              const deleteSecondIndex = navSecondIndex;
              const parentAvailable = user.rolePermissions.find((item) => item.permissionId === navSecond.id);
              if (parentAvailable === undefined) {
                parentIndexArr.push(navSecond.id);
                //  navigation[navFirstIndex].children[navSecondIndex].auth = user.roleName;
              }

              if (navSecond?.children) {
                navSecond.children.forEach((navThird, navThirdIndex) => {
                  user.rolePermissions.forEach((perFirst) => {
                    if (perFirst?.children) {
                      const foundData = perFirst.children.find((item) => item === navThird.id);
                      if (foundData) {
                        indexArr.push(navThirdIndex);
                        // navigation[navFirstIndex].children[navSecondIndex].children.auth = user.roleName;
                      }
                    }
                  });
                });
              } else {
                // console.log("no children");
              }

              if (indexArr.length > 0) {
                const tempArr = [];
                navigation[navFirstIndex].children[deleteSecondIndex].children.forEach((item, itemIndex) => {
                  const data = indexArr.find((findItem) => findItem === itemIndex);
                  if (data !== undefined) {
                    tempArr.push(item);
                  }
                });
                if (navigation[navFirstIndex].children[deleteSecondIndex].children.length > 0 && tempArr.length === 0) {
                  navigation[navFirstIndex].children.splice(deleteSecondIndex, 1);
                } else {
                  navigation[navFirstIndex].children[deleteSecondIndex].children = tempArr;
                }
              }
            });
            // console.log(parentIndexArr);
            if (parentIndexArr.length > 0) {
              navigation[navFirstIndex].children.reduceRight((acc, obj, idx) => {
                if (parentIndexArr.indexOf(obj.id) > -1) return navigation[navFirstIndex].children.splice(idx, 1);
                return null;
              }, 0);
              // for(let i=0;i<navigation[navFirstIndex].children.length;i++){
              //   if(parentIndexArr.find((item) => item === navigation[navFirstIndex].children[i].title)){
              //     navigation[navFirstIndex].children.splice(i, 1);
              //     i--;
              //   }
              // }
            }
          }
        });
      }
      navigation.forEach((item, i) => (item.children.length === 0 ? navigation.splice(i, 1) : item));
    }
    dispatch(setNavigation(navigation));
  }, []);

  return useMemo(() => {
    const handleItemClick = () => {
      if (isMobile) {
        dispatch(navbarCloseMobile());
      }
    };

    return <FuseNavigation className={clsx('navigation', props.className)} navigation={navigation} layout={props.layout} dense={props.dense} active={props.active} onItemClick={handleItemClick} />;
  }, [dispatch, isMobile, navigation, props.active, props.className, props.dense, props.layout]);
}

Navigation.defaultProps = {
  layout: 'vertical',
};

export default memo(Navigation);
