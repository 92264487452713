import { useEffect, useState, useMemo } from 'react';
import { selectUser } from 'app/store/userSlice';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

/* Custom hook to render page contents
 * Evaluate and Return Permissions for Each Page
 */

export const perm = [];

export const usePermHook = (pagePerm) => {
  const [permission, setPermission] = useState(undefined);
  const user = useSelector(selectUser);

  const { pathname } = useLocation();

  useEffect(() => {
    if (user?.rolePermissions) {
      const filterParent = user?.rolePermissions?.filter((item) => item.level === 1);
      if (filterParent) {
        const findParent = filterParent.find((value) => value.permissionId === pagePerm.parentId);
        if (findParent && findParent?.children.length > 0) {
          const findChild = pagePerm?.children?.reduce((a, c) => {
            const childValue = findParent?.children?.find((item) => item === c);
            if (childValue) {
              return { ...a, [c]: true };
            }
            return { ...a, [c]: false };
          }, {});
          setPermission(findChild);
        } else {
          const findChild = pagePerm?.children?.reduce((a, c) => {
            return { ...a, [c]: false };
          }, {});
          setPermission(findChild);
        }
      } else {
        const findChild = pagePerm?.children?.reduce((a, c) => {
          return { ...a, [c]: false };
        }, {});
        setPermission(findChild);
      }
    }
  }, [pathname]);
  return useMemo(() => ({ permission }), [permission]);
};
