/* eslint-disable react/jsx-props-no-spreading */
import { FormLabel, Switch } from '@mui/material';
import { Field } from 'formik';

const MuiSwitch = (props) => {
  const { name, errors, touched, label, ...rest } = props;
  return (
    <div>
      <FormLabel>{label}</FormLabel>
      <Field as={Switch} name={name} variant="standard" {...rest} />
    </div>
  );
};

export default MuiSwitch;
