import { useState, useEffect } from 'react';
import AgGrid from 'app/shared-components/ag-grid/AgGrid';
import axios from 'axios';
import { Formik } from 'formik';
import { Paper, Box, Typography } from '@mui/material';
import { jwtServiceConfig } from 'src/app/auth/services/jwtService/jwtServiceConfig';
import { usePermHook } from 'src/app/custom-hooks/user-permission-hook/userPermHook';
import { permissions } from 'src/app/custom-hooks/user-permission-hook/permissions';
import MuiFormDialog from 'app/shared-components/mui/dialog/MuiFormDialog';
import FormikController from 'app/shared-components/formik-controller/FormikController';
import { handleDateTime } from 'app/shared-components/page-utils/pageUtils';
import MuiButton from 'app/shared-components/mui/button/MuiButton';
import GridContainer from 'app/shared-components/mui/grid/GridContainer';
import GridItem from 'app/shared-components/mui/grid/GridItem';
import { handleResponse } from 'app/shared-components/index';
import ActionRendererComponent from 'app/shared-components/action-render/ActionRendererComponent';
import { validationSchemaServicePage, initialValuesServicePage } from '../helperFuntionQueueManagement';

/* Service Page
 * Bank admin can create multiple services.
 * The default service status is Inactive.
 * Service status can be changed by the edit option.
 */

const ServicePage = () => {
  const [servicePageControl, setServicePageControl] = useState({
    isOpenDialog: false,
    isEdit: false,
    selectedValues: undefined,
  });
  const [rowData, setRowData] = useState(undefined);
  const { permission } = usePermHook(permissions?.queueManagement?.servicePage);
  const dialogTitle = servicePageControl?.isEdit ? 'Edit Service' : 'Add Service';

  const handleView = (data) => setServicePageControl((prev) => ({ ...prev, isOpenDialog: true, isEdit: true, selectedValues: data }));

  let columnDefs = [
    {
      field: 'serviceName',
      headerName: ' Name',
    },
    {
      field: 'serviceCode',
      headerName: ' Code',
    },
    {
      field: 'serviceStatus',
      headerName: 'Status',
      valueGetter: ({ data: { serviceStatus } }) => (serviceStatus === 'A' ? 'Active' : 'Suspended'),
    },
    {
      headerName: 'Created DateTime',
      valueGetter: ({ data: { createdDatetime } }) => handleDateTime(createdDatetime),
    },
    {
      field: 'createdBy',
      headerName: 'Created by',
    },
    {
      headerName: 'Modified DateTime',
      valueGetter: ({ data: { lastUpdatedDatetime } }) => handleDateTime(lastUpdatedDatetime),
    },
    {
      field: 'lastUpdatedBy',
      headerName: 'Modified by',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      cellRenderer: ({ data }) => ActionRendererComponent('isEdit', data, handleView),
    },
  ];

  columnDefs = permission && permission?.P00217 === true ? columnDefs : columnDefs.slice(0, -1);

  const getServicesList = async () => {
    const res = await axios.post(jwtServiceConfig.serviceView, {});
    console.log(res);
    if (res.data.responseCode === 0) {
      setRowData(res.data.data);
    } else {
      handleResponse(res);
    }
  };

  useEffect(() => {
    getServicesList();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    console.log(values);
    if (servicePageControl?.isEdit === false) {
      const data = {
        serviceCode: values.serviceCode,
        serviceStatus: values.serviceStatus,
        serviceName: values.serviceName,
      };

      const res = await axios.post(jwtServiceConfig.serviceAdd, data);
      if (res.data.responseCode === 0) {
        handleResponse(res);
        setServicePageControl((prev) => ({ ...prev, isOpenDialog: false, isEdit: false }));
        resetForm();
        getServicesList();
      } else {
        handleResponse(res);
      }
    } else {
      const data = {
        id: values.id,
        serviceCode: values.serviceCode,
        serviceStatus: values.serviceStatus,
        serviceName: values.serviceName,
      };

      const res = await axios.post(jwtServiceConfig.serviceEdit, data);

      if (res.data.responseCode === 0) {
        handleResponse(res);
        setServicePageControl((prev) => ({ ...prev, isOpenDialog: false, isEdit: false }));
        resetForm();
        getServicesList();
      } else {
        handleResponse(res);
      }
    }
  };

  const handleCloseServiceDialog = (resetForm) => {
    resetForm();
    setServicePageControl((prev) => ({ ...prev, isOpenDialog: false, isEdit: false }));
  };

  return (
    <Paper className="relative flex flex-col flex-auto p-24 prounded-2xl shadow overflow-hidden">
      <Box className="mt-4 ml-8 flex flex-row justify-between ">
        <Box>
          <Typography className="text-3xl font-semibold tracking-tight leading-8">Service Configuration</Typography>
          <Box>
            <Box>
              <h3>
                <span className="text-md">Queue Management &gt; Service Configuration</span>
              </h3>
            </Box>
          </Box>
        </Box>
        {permission?.P00216 && (
          <Box className="mt-24">
            <MuiButton
              type="basic"
              onClick={() => {
                setServicePageControl((prev) => ({ ...prev, isOpenDialog: true, isEdit: false }));
              }}
            >
              Add
            </MuiButton>
          </Box>
        )}
      </Box>
      <Box className="mt-16">
        <AgGrid rowData={rowData} columnDefs={columnDefs} overlayNoRowsTemplate="No services configured, please configure services" />
      </Box>
      <Formik
        initialValues={servicePageControl?.isEdit ? servicePageControl?.selectedValues : initialValuesServicePage}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchemaServicePage}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, resetForm }) => (
          <MuiFormDialog open={servicePageControl?.isOpenDialog} dialogTitle={dialogTitle} onClose={() => handleCloseServiceDialog(resetForm)}>
            <GridContainer spacing={2}>
              <GridItem xs={12}>
                <FormikController control="textField" name="serviceCode" label="Service Code" disabled={servicePageControl?.isEdit} required />
              </GridItem>
              <GridItem xs={12}>
                <FormikController control="textField" name="serviceName" label="Service Name" required inputProps={{ maxLength: 25 }} />
              </GridItem>
              {servicePageControl?.isEdit && permission?.P00218 && (
                <GridItem xs={12}>
                  <FormikController
                    control="switch"
                    name="serviceStatus"
                    label="Status"
                    checked={values?.serviceStatus === 'A'}
                    onChange={(e) => {
                      setFieldValue('serviceStatus', e.target.checked ? 'A' : 'I');
                    }}
                  />
                </GridItem>
              )}
            </GridContainer>
          </MuiFormDialog>
        )}
      </Formik>
    </Paper>
  );
};
export default ServicePage;
