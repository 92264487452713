import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    loading: false,
  },
  reducers: {
    showDisableLoader: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const selectLoader = ({ loader }) => loader.loading;

export const { showDisableLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
