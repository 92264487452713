import Typography from '@mui/material/Typography';

const QueueDetails = ({ queue }) => {
  // console.log(queue);
  return (
    <div className="bg-gray-100 p-12 h-[250px]">
      <div className="flex flex-col justify-center items-center">
        <Typography className="text-5xl font-bold tracking-tighter leading-tight">{queue?.queueNumber}</Typography>
        <div className="my-12">
          <div className="grid sm:grid-cols-2 sm:grid-rows-auto gap-8">
            {queue?.time && (
              <div>
                <Typography className=" text-left">Time</Typography>
                <Typography className="font-sm font-semibold inline-block break-all">{queue?.time}</Typography>
              </div>
            )}
            {queue?.serviceName && (
              <div>
                <Typography className=" text-left">Service</Typography>
                <Typography className="font-sm font-semibold inline-block break-all">{queue?.serviceName}</Typography>
              </div>
            )}
            {queue?.userCode && (
              <div>
                <Typography className=" text-left">User ID</Typography>
                <Typography className="font-sm font-semibold inline-block break-all">{queue?.userCode}</Typography>
              </div>
            )}
            {queue?.agencyCode && (
              <div>
                <Typography className=" text-left">Agency</Typography>
                <Typography className="font-sm font-semibold inline-block break-all">{queue?.agencyCode}</Typography>
              </div>
            )}
            {queue?.stagingRefNum && (
              <div className="col-span-2">
                <Typography className=" text-left">Staging Reference Number</Typography>
                <Typography className="font-sm font-semibold inline-block break-all">{queue?.stagingRefNum}</Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QueueDetails;
